.footer {

    background-color: $light-gray;

    .footer-subline{
        background-color: $light-gray;
    }

    .footer-subline{
        border-top: 1px solid $medium-gray;

        .menu{
            li{
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }

}
