section.img {
    img {
        object-fit: cover;
        max-height: 600px;
        width: 100%;
    }

    @include breakpoint(large) {
        img {
            max-height: 800px;
        }
    }
}