section.team {

    .photo {
        img{
            border-radius: 50%;
        }
    }

    .description{
        p{
            margin-bottom: 0;
        }        
    }

}
