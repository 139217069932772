@charset "UTF-8";
p.blue,
h1.blue,
h2.blue,
h3.blue,
h4.blue,
h5.blue,
a.blue,
em.blue,
b.blue,
.menu a.blue,
span.blue {
  color: #012c9f; }
  p.blue svg path,
  p.blue svg polyline,
  p.blue svg polygon,
  p.blue svg line,
  p.blue svg rect,
  p.blue svg circle,
  h1.blue svg path,
  h1.blue svg polyline,
  h1.blue svg polygon,
  h1.blue svg line,
  h1.blue svg rect,
  h1.blue svg circle,
  h2.blue svg path,
  h2.blue svg polyline,
  h2.blue svg polygon,
  h2.blue svg line,
  h2.blue svg rect,
  h2.blue svg circle,
  h3.blue svg path,
  h3.blue svg polyline,
  h3.blue svg polygon,
  h3.blue svg line,
  h3.blue svg rect,
  h3.blue svg circle,
  h4.blue svg path,
  h4.blue svg polyline,
  h4.blue svg polygon,
  h4.blue svg line,
  h4.blue svg rect,
  h4.blue svg circle,
  h5.blue svg path,
  h5.blue svg polyline,
  h5.blue svg polygon,
  h5.blue svg line,
  h5.blue svg rect,
  h5.blue svg circle,
  a.blue svg path,
  a.blue svg polyline,
  a.blue svg polygon,
  a.blue svg line,
  a.blue svg rect,
  a.blue svg circle,
  em.blue svg path,
  em.blue svg polyline,
  em.blue svg polygon,
  em.blue svg line,
  em.blue svg rect,
  em.blue svg circle,
  b.blue svg path,
  b.blue svg polyline,
  b.blue svg polygon,
  b.blue svg line,
  b.blue svg rect,
  b.blue svg circle,
  .menu a.blue svg path,
  .menu a.blue svg polyline,
  .menu a.blue svg polygon,
  .menu a.blue svg line,
  .menu a.blue svg rect,
  .menu a.blue svg circle,
  span.blue svg path,
  span.blue svg polyline,
  span.blue svg polygon,
  span.blue svg line,
  span.blue svg rect,
  span.blue svg circle {
    stroke: #012c9f; }

.menu a.blue:hover,
a.blue:hover {
  transition: color 300ms ease;
  color: #012177; }
  .menu a.blue:hover svg path,
  .menu a.blue:hover svg polyline,
  .menu a.blue:hover svg polygon,
  .menu a.blue:hover svg line,
  .menu a.blue:hover svg rect,
  .menu a.blue:hover svg circle,
  a.blue:hover svg path,
  a.blue:hover svg polyline,
  a.blue:hover svg polygon,
  a.blue:hover svg line,
  a.blue:hover svg rect,
  a.blue:hover svg circle {
    stroke: #012177; }

svg.blue g.shade path,
svg.blue g.shade polyline,
svg.blue g.shade polygon,
svg.blue g.shade line,
svg.blue g.shade rect,
svg.blue g.shade circle {
  fill: rgba(1, 44, 159, 0.2) !important; }

svg.blue g.outline path,
svg.blue g.outline polyline,
svg.blue g.outline polygon,
svg.blue g.outline line,
svg.blue g.outline rect,
svg.blue g.outline circle {
  stroke: #012c9f !important; }

svg.link-arrow.blue path,
svg.link-arrow.blue polyline,
svg.link-arrow.blue polygon,
svg.link-arrow.blue line,
svg.link-arrow.blue rect,
svg.link-arrow.blue circle {
  stroke: #012c9f !important; }

svg.link-arrow.blue:hover svg.link-arrow.blue path,
svg.link-arrow.blue:hover svg.link-arrow.blue polyline,
svg.link-arrow.blue:hover svg.link-arrow.blue polygon,
svg.link-arrow.blue:hover svg.link-arrow.blue line,
svg.link-arrow.blue:hover svg.link-arrow.blue rect,
svg.link-arrow.blue:hover svg.link-arrow.blue circle {
  stroke: #012177 !important; }

a.button.blue,
button.blue {
  background-color: transparent !important;
  background: linear-gradient(52deg, #012177 0%, #5180fe 100%);
  color: #fefefe;
  background-size: 200% 200%;
  transform: translateZ(0);
  background-position: 100% 0%;
  will-change: background-position;
  animation: gradient-scroll-back 700ms ease both; }
  a.button.blue:hover,
  button.blue:hover {
    animation: gradient-scroll 700ms ease both; }

div.bg-gradient-blue {
  background-color: #fefefe;
  background-image: linear-gradient(17deg, rgba(1, 44, 159, 0.4) 10%, rgba(1, 44, 159, 0.1) 120%); }

.blue a {
  color: #012c9f; }

.blue p a {
  color: #012c9f; }

p.green,
h1.green,
h2.green,
h3.green,
h4.green,
h5.green,
a.green,
em.green,
b.green,
.menu a.green,
span.green {
  color: #008a00; }
  p.green svg path,
  p.green svg polyline,
  p.green svg polygon,
  p.green svg line,
  p.green svg rect,
  p.green svg circle,
  h1.green svg path,
  h1.green svg polyline,
  h1.green svg polygon,
  h1.green svg line,
  h1.green svg rect,
  h1.green svg circle,
  h2.green svg path,
  h2.green svg polyline,
  h2.green svg polygon,
  h2.green svg line,
  h2.green svg rect,
  h2.green svg circle,
  h3.green svg path,
  h3.green svg polyline,
  h3.green svg polygon,
  h3.green svg line,
  h3.green svg rect,
  h3.green svg circle,
  h4.green svg path,
  h4.green svg polyline,
  h4.green svg polygon,
  h4.green svg line,
  h4.green svg rect,
  h4.green svg circle,
  h5.green svg path,
  h5.green svg polyline,
  h5.green svg polygon,
  h5.green svg line,
  h5.green svg rect,
  h5.green svg circle,
  a.green svg path,
  a.green svg polyline,
  a.green svg polygon,
  a.green svg line,
  a.green svg rect,
  a.green svg circle,
  em.green svg path,
  em.green svg polyline,
  em.green svg polygon,
  em.green svg line,
  em.green svg rect,
  em.green svg circle,
  b.green svg path,
  b.green svg polyline,
  b.green svg polygon,
  b.green svg line,
  b.green svg rect,
  b.green svg circle,
  .menu a.green svg path,
  .menu a.green svg polyline,
  .menu a.green svg polygon,
  .menu a.green svg line,
  .menu a.green svg rect,
  .menu a.green svg circle,
  span.green svg path,
  span.green svg polyline,
  span.green svg polygon,
  span.green svg line,
  span.green svg rect,
  span.green svg circle {
    stroke: #008a00; }

.menu a.green:hover,
a.green:hover {
  transition: color 300ms ease;
  color: #006800; }
  .menu a.green:hover svg path,
  .menu a.green:hover svg polyline,
  .menu a.green:hover svg polygon,
  .menu a.green:hover svg line,
  .menu a.green:hover svg rect,
  .menu a.green:hover svg circle,
  a.green:hover svg path,
  a.green:hover svg polyline,
  a.green:hover svg polygon,
  a.green:hover svg line,
  a.green:hover svg rect,
  a.green:hover svg circle {
    stroke: #006800; }

svg.green g.shade path,
svg.green g.shade polyline,
svg.green g.shade polygon,
svg.green g.shade line,
svg.green g.shade rect,
svg.green g.shade circle {
  fill: rgba(0, 138, 0, 0.2) !important; }

svg.green g.outline path,
svg.green g.outline polyline,
svg.green g.outline polygon,
svg.green g.outline line,
svg.green g.outline rect,
svg.green g.outline circle {
  stroke: #008a00 !important; }

svg.link-arrow.green path,
svg.link-arrow.green polyline,
svg.link-arrow.green polygon,
svg.link-arrow.green line,
svg.link-arrow.green rect,
svg.link-arrow.green circle {
  stroke: #008a00 !important; }

svg.link-arrow.green:hover svg.link-arrow.green path,
svg.link-arrow.green:hover svg.link-arrow.green polyline,
svg.link-arrow.green:hover svg.link-arrow.green polygon,
svg.link-arrow.green:hover svg.link-arrow.green line,
svg.link-arrow.green:hover svg.link-arrow.green rect,
svg.link-arrow.green:hover svg.link-arrow.green circle {
  stroke: #006800 !important; }

a.button.green,
button.green {
  background-color: transparent !important;
  background: linear-gradient(52deg, #006800 0%, #45ff45 100%);
  color: #fefefe;
  background-size: 200% 200%;
  transform: translateZ(0);
  background-position: 100% 0%;
  will-change: background-position;
  animation: gradient-scroll-back 700ms ease both; }
  a.button.green:hover,
  button.green:hover {
    animation: gradient-scroll 700ms ease both; }

div.bg-gradient-green {
  background-color: #fefefe;
  background-image: linear-gradient(17deg, rgba(0, 138, 0, 0.4) 10%, rgba(0, 138, 0, 0.1) 120%); }

.green a {
  color: #008a00; }

.green p a {
  color: #008a00; }

p.red,
h1.red,
h2.red,
h3.red,
h4.red,
h5.red,
a.red,
em.red,
b.red,
.menu a.red,
span.red {
  color: #e74436; }
  p.red svg path,
  p.red svg polyline,
  p.red svg polygon,
  p.red svg line,
  p.red svg rect,
  p.red svg circle,
  h1.red svg path,
  h1.red svg polyline,
  h1.red svg polygon,
  h1.red svg line,
  h1.red svg rect,
  h1.red svg circle,
  h2.red svg path,
  h2.red svg polyline,
  h2.red svg polygon,
  h2.red svg line,
  h2.red svg rect,
  h2.red svg circle,
  h3.red svg path,
  h3.red svg polyline,
  h3.red svg polygon,
  h3.red svg line,
  h3.red svg rect,
  h3.red svg circle,
  h4.red svg path,
  h4.red svg polyline,
  h4.red svg polygon,
  h4.red svg line,
  h4.red svg rect,
  h4.red svg circle,
  h5.red svg path,
  h5.red svg polyline,
  h5.red svg polygon,
  h5.red svg line,
  h5.red svg rect,
  h5.red svg circle,
  a.red svg path,
  a.red svg polyline,
  a.red svg polygon,
  a.red svg line,
  a.red svg rect,
  a.red svg circle,
  em.red svg path,
  em.red svg polyline,
  em.red svg polygon,
  em.red svg line,
  em.red svg rect,
  em.red svg circle,
  b.red svg path,
  b.red svg polyline,
  b.red svg polygon,
  b.red svg line,
  b.red svg rect,
  b.red svg circle,
  .menu a.red svg path,
  .menu a.red svg polyline,
  .menu a.red svg polygon,
  .menu a.red svg line,
  .menu a.red svg rect,
  .menu a.red svg circle,
  span.red svg path,
  span.red svg polyline,
  span.red svg polygon,
  span.red svg line,
  span.red svg rect,
  span.red svg circle {
    stroke: #e74436; }

.menu a.red:hover,
a.red:hover {
  transition: color 300ms ease;
  color: #bf2417; }
  .menu a.red:hover svg path,
  .menu a.red:hover svg polyline,
  .menu a.red:hover svg polygon,
  .menu a.red:hover svg line,
  .menu a.red:hover svg rect,
  .menu a.red:hover svg circle,
  a.red:hover svg path,
  a.red:hover svg polyline,
  a.red:hover svg polygon,
  a.red:hover svg line,
  a.red:hover svg rect,
  a.red:hover svg circle {
    stroke: #bf2417; }

svg.red g.shade path,
svg.red g.shade polyline,
svg.red g.shade polygon,
svg.red g.shade line,
svg.red g.shade rect,
svg.red g.shade circle {
  fill: rgba(231, 68, 54, 0.2) !important; }

svg.red g.outline path,
svg.red g.outline polyline,
svg.red g.outline polygon,
svg.red g.outline line,
svg.red g.outline rect,
svg.red g.outline circle {
  stroke: #e74436 !important; }

svg.link-arrow.red path,
svg.link-arrow.red polyline,
svg.link-arrow.red polygon,
svg.link-arrow.red line,
svg.link-arrow.red rect,
svg.link-arrow.red circle {
  stroke: #e74436 !important; }

svg.link-arrow.red:hover svg.link-arrow.red path,
svg.link-arrow.red:hover svg.link-arrow.red polyline,
svg.link-arrow.red:hover svg.link-arrow.red polygon,
svg.link-arrow.red:hover svg.link-arrow.red line,
svg.link-arrow.red:hover svg.link-arrow.red rect,
svg.link-arrow.red:hover svg.link-arrow.red circle {
  stroke: #bf2417 !important; }

a.button.red,
button.red {
  background-color: transparent !important;
  background: linear-gradient(52deg, #bf2417 0%, #f3a29b 100%);
  color: #fefefe;
  background-size: 200% 200%;
  transform: translateZ(0);
  background-position: 100% 0%;
  will-change: background-position;
  animation: gradient-scroll-back 700ms ease both; }
  a.button.red:hover,
  button.red:hover {
    animation: gradient-scroll 700ms ease both; }

div.bg-gradient-red {
  background-color: #fefefe;
  background-image: linear-gradient(17deg, rgba(231, 68, 54, 0.4) 10%, rgba(231, 68, 54, 0.1) 120%); }

.red a {
  color: #e74436; }

.red p a {
  color: #e74436; }

p.yellow,
h1.yellow,
h2.yellow,
h3.yellow,
h4.yellow,
h5.yellow,
a.yellow,
em.yellow,
b.yellow,
.menu a.yellow,
span.yellow {
  color: #ffc800; }
  p.yellow svg path,
  p.yellow svg polyline,
  p.yellow svg polygon,
  p.yellow svg line,
  p.yellow svg rect,
  p.yellow svg circle,
  h1.yellow svg path,
  h1.yellow svg polyline,
  h1.yellow svg polygon,
  h1.yellow svg line,
  h1.yellow svg rect,
  h1.yellow svg circle,
  h2.yellow svg path,
  h2.yellow svg polyline,
  h2.yellow svg polygon,
  h2.yellow svg line,
  h2.yellow svg rect,
  h2.yellow svg circle,
  h3.yellow svg path,
  h3.yellow svg polyline,
  h3.yellow svg polygon,
  h3.yellow svg line,
  h3.yellow svg rect,
  h3.yellow svg circle,
  h4.yellow svg path,
  h4.yellow svg polyline,
  h4.yellow svg polygon,
  h4.yellow svg line,
  h4.yellow svg rect,
  h4.yellow svg circle,
  h5.yellow svg path,
  h5.yellow svg polyline,
  h5.yellow svg polygon,
  h5.yellow svg line,
  h5.yellow svg rect,
  h5.yellow svg circle,
  a.yellow svg path,
  a.yellow svg polyline,
  a.yellow svg polygon,
  a.yellow svg line,
  a.yellow svg rect,
  a.yellow svg circle,
  em.yellow svg path,
  em.yellow svg polyline,
  em.yellow svg polygon,
  em.yellow svg line,
  em.yellow svg rect,
  em.yellow svg circle,
  b.yellow svg path,
  b.yellow svg polyline,
  b.yellow svg polygon,
  b.yellow svg line,
  b.yellow svg rect,
  b.yellow svg circle,
  .menu a.yellow svg path,
  .menu a.yellow svg polyline,
  .menu a.yellow svg polygon,
  .menu a.yellow svg line,
  .menu a.yellow svg rect,
  .menu a.yellow svg circle,
  span.yellow svg path,
  span.yellow svg polyline,
  span.yellow svg polygon,
  span.yellow svg line,
  span.yellow svg rect,
  span.yellow svg circle {
    stroke: #ffc800; }

.menu a.yellow:hover,
a.yellow:hover {
  transition: color 300ms ease;
  color: #bf9600; }
  .menu a.yellow:hover svg path,
  .menu a.yellow:hover svg polyline,
  .menu a.yellow:hover svg polygon,
  .menu a.yellow:hover svg line,
  .menu a.yellow:hover svg rect,
  .menu a.yellow:hover svg circle,
  a.yellow:hover svg path,
  a.yellow:hover svg polyline,
  a.yellow:hover svg polygon,
  a.yellow:hover svg line,
  a.yellow:hover svg rect,
  a.yellow:hover svg circle {
    stroke: #bf9600; }

svg.yellow g.shade path,
svg.yellow g.shade polyline,
svg.yellow g.shade polygon,
svg.yellow g.shade line,
svg.yellow g.shade rect,
svg.yellow g.shade circle {
  fill: rgba(255, 200, 0, 0.2) !important; }

svg.yellow g.outline path,
svg.yellow g.outline polyline,
svg.yellow g.outline polygon,
svg.yellow g.outline line,
svg.yellow g.outline rect,
svg.yellow g.outline circle {
  stroke: #ffc800 !important; }

svg.link-arrow.yellow path,
svg.link-arrow.yellow polyline,
svg.link-arrow.yellow polygon,
svg.link-arrow.yellow line,
svg.link-arrow.yellow rect,
svg.link-arrow.yellow circle {
  stroke: #ffc800 !important; }

svg.link-arrow.yellow:hover svg.link-arrow.yellow path,
svg.link-arrow.yellow:hover svg.link-arrow.yellow polyline,
svg.link-arrow.yellow:hover svg.link-arrow.yellow polygon,
svg.link-arrow.yellow:hover svg.link-arrow.yellow line,
svg.link-arrow.yellow:hover svg.link-arrow.yellow rect,
svg.link-arrow.yellow:hover svg.link-arrow.yellow circle {
  stroke: #bf9600 !important; }

a.button.yellow,
button.yellow {
  background-color: transparent !important;
  background: linear-gradient(52deg, #bf9600 0%, #ffe480 100%);
  color: #fefefe;
  background-size: 200% 200%;
  transform: translateZ(0);
  background-position: 100% 0%;
  will-change: background-position;
  animation: gradient-scroll-back 700ms ease both; }
  a.button.yellow:hover,
  button.yellow:hover {
    animation: gradient-scroll 700ms ease both; }

div.bg-gradient-yellow {
  background-color: #fefefe;
  background-image: linear-gradient(17deg, rgba(255, 200, 0, 0.4) 10%, rgba(255, 200, 0, 0.1) 120%); }

.yellow a {
  color: #ffc800; }

.yellow p a {
  color: #ffc800; }

button:hover svg g.shade path,
button:hover svg g.shade polyline,
button:hover svg g.shade line,
button:hover svg g.shade rect,
button:hover svg g.shade circle polyline,
a:hover svg g.shade path,
a:hover svg g.shade polyline,
a:hover svg g.shade line,
a:hover svg g.shade rect,
a:hover svg g.shade circle polyline {
  fill: rgba(1, 44, 159, 0.2); }

button:hover svg g.outline path,
button:hover svg g.outline polyline,
button:hover svg g.outline line,
button:hover svg g.outline rect,
button:hover svg g.outline circle,
a:hover svg g.outline path,
a:hover svg g.outline polyline,
a:hover svg g.outline line,
a:hover svg g.outline rect,
a:hover svg g.outline circle {
  stroke: #012c9f; }

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=53.125em&large=96.875em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 21px; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
  color: #2e2e2e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 76.19048rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.47619rem;
    margin-left: -0.47619rem; }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.71429rem;
        margin-left: -0.71429rem; } }
    @media print, screen and (min-width: 96.875em) {
      .row .row {
        margin-right: -0.71429rem;
        margin-left: -0.71429rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.47619rem;
    padding-left: 0.47619rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.47619rem;
  padding-left: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .column, .columns {
      padding-right: 0.71429rem;
      padding-left: 0.71429rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.47619rem;
  padding-left: 0.47619rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 53.125em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 0.95238rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column-block {
      margin-bottom: 1.42857rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 76.19048rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.47619rem;
    margin-left: -0.47619rem; }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.71429rem;
        margin-left: -0.71429rem; } }
    @media print, screen and (min-width: 96.875em) {
      .row .row {
        margin-right: -0.71429rem;
        margin-left: -0.71429rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.47619rem;
  padding-left: 0.47619rem;
  min-width: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column, .columns {
      padding-right: 0.71429rem;
      padding-left: 0.71429rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.47619rem;
  padding-left: 0.47619rem; }

@media print, screen and (min-width: 53.125em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 53.125em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 53.125em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem; } }

@media print, screen and (min-width: 96.875em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 96.875em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 96.875em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 0.95238rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column-block {
      margin-bottom: 1.42857rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  padding-right: 0.47619rem;
  padding-left: 0.47619rem;
  max-width: 76.19048rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 53.125em) {
    .grid-container {
      padding-right: 0.71429rem;
      padding-left: 0.71429rem; } }
  .grid-container.fluid {
    padding-right: 0.47619rem;
    padding-left: 0.47619rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 53.125em) {
      .grid-container.fluid {
        padding-right: 0.71429rem;
        padding-left: 0.71429rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 53.125em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 96.875em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 53.125em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.47619rem;
  margin-right: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x {
      margin-left: -0.71429rem;
      margin-right: -0.71429rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 0.95238rem);
    margin-left: 0.47619rem;
    margin-right: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.42857rem);
      margin-left: 0.71429rem;
      margin-right: 0.71429rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 0.95238rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 0.95238rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 0.95238rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 0.95238rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 0.95238rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 0.95238rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 0.95238rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 0.95238rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 0.95238rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 0.95238rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 0.95238rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 0.95238rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.42857rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.42857rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.42857rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.42857rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.42857rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.42857rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.42857rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.42857rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.42857rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.42857rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.42857rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.42857rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.42857rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.42857rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.42857rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.42857rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.42857rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.42857rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.42857rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.42857rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.42857rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.42857rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.42857rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.42857rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.42857rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.42857rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.42857rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.42857rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.42857rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.42857rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.42857rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.42857rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.42857rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.42857rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.42857rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.42857rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.47619rem;
  margin-left: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.71429rem;
      margin-left: -0.71429rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.47619rem;
  margin-left: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.71429rem;
      margin-left: -0.71429rem; } }

.grid-padding-x > .cell {
  padding-right: 0.47619rem;
  padding-left: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-x > .cell {
      padding-right: 0.71429rem;
      padding-left: 0.71429rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 53.125em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 96.875em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 0.95238rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 0.95238rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 0.95238rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 0.95238rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 0.95238rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 0.95238rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 0.95238rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 0.95238rem); }

@media print, screen and (min-width: 53.125em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 0.95238rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 0.95238rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 0.95238rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 0.95238rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 0.95238rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 0.95238rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 0.95238rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 0.95238rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.42857rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.42857rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.42857rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.42857rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.42857rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.42857rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.42857rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.42857rem); } }

@media print, screen and (min-width: 96.875em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.42857rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.42857rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.42857rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.42857rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.42857rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.42857rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.42857rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.42857rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 96.875em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.47619rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.47619rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.47619rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.47619rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.47619rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.47619rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.47619rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.47619rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.47619rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.47619rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.47619rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.47619rem); }

@media print, screen and (min-width: 53.125em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.71429rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.71429rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.71429rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.71429rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.71429rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.71429rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.71429rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.71429rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.71429rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.71429rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.71429rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.71429rem); } }

@media print, screen and (min-width: 96.875em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.71429rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.71429rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.71429rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.71429rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.71429rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.71429rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.71429rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.71429rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.71429rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.71429rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.71429rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.71429rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 53.125em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 96.875em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 96.875em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.47619rem;
  margin-bottom: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.71429rem;
      margin-bottom: -0.71429rem; } }

.grid-padding-y > .cell {
  padding-top: 0.47619rem;
  padding-bottom: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-y > .cell {
      padding-top: 0.71429rem;
      padding-bottom: 0.71429rem; } }

.grid-margin-y {
  margin-top: -0.47619rem;
  margin-bottom: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y {
      margin-top: -0.71429rem;
      margin-bottom: -0.71429rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.95238rem);
    margin-top: 0.47619rem;
    margin-bottom: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.42857rem);
      margin-top: 0.71429rem;
      margin-bottom: 0.71429rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.95238rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.95238rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.95238rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.95238rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.95238rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.95238rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.95238rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.95238rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.95238rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.95238rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.95238rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.95238rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.42857rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.42857rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.42857rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 53.125em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 96.875em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 53.125em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 96.875em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 53.125em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.47619rem;
  margin-bottom: -0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y {
      margin-top: -0.71429rem;
      margin-bottom: -0.71429rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.95238rem);
    margin-top: 0.47619rem;
    margin-bottom: 0.47619rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.42857rem);
      margin-top: 0.71429rem;
      margin-bottom: 0.71429rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.95238rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.95238rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.95238rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.95238rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.95238rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.95238rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.95238rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.95238rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.95238rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.95238rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.95238rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.95238rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.42857rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.42857rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.42857rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.42857rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.42857rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.42857rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.42857rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.42857rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.42857rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.42857rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.42857rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.42857rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.42857rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.42857rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 0.95238rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.42857rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.42857rem); } }

@media print, screen and (min-width: 53.125em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.42857rem); } }

@media print, screen and (min-width: 96.875em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.42857rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 75%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #2e2e2e; }

h1, .h1 {
  font-size: 1.52381rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.14286rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 53.125em) {
  h1, .h1 {
    font-size: 1.90476rem; }
  h2, .h2 {
    font-size: 1.52381rem; }
  h3, .h3 {
    font-size: 1.14286rem; }
  h4, .h4 {
    font-size: 1rem; }
  h5, .h5 {
    font-size: 1rem; }
  h6, .h6 {
    font-size: 1rem; } }

@media print, screen and (min-width: 96.875em) {
  h1, .h1 {
    font-size: 1.90476rem; }
  h2, .h2 {
    font-size: 1.52381rem; }
  h3, .h3 {
    font-size: 1.14286rem; }
  h4, .h4 {
    font-size: 1rem; }
  h5, .h5 {
    font-size: 1rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #012c9f;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #012177; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 76.19048rem;
  height: 0;
  margin: 0.95238rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #A1A1A1;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.5; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0 0 0 1.19048rem;
  border-left: 4px solid #012c9f; }
  blockquote, blockquote p {
    line-height: 1.5;
    color: #2e2e2e; }

cite {
  display: block;
  font-size: 0.61905rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.09524rem 0.2381rem 0.04762rem;
  border: 1px solid #A1A1A1;
  background-color: #f4f4f4;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000000; }

kbd {
  margin: 0;
  padding: 0.09524rem 0.19048rem 0;
  background-color: #f4f4f4;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 42px;
  line-height: 1; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 53.125em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 96.875em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 2em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #012c9f;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #012587;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #012c9f;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #01237f;
      color: #fefefe; }
  .button.secondary {
    background-color: #008a00;
    color: #000000; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #006e00;
      color: #000000; }
  .button.success {
    background-color: #008a00;
    color: #000000; }
    .button.success:hover, .button.success:focus {
      background-color: #006e00;
      color: #000000; }
  .button.warning {
    background-color: #ffc800;
    color: #000000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cca000;
      color: #000000; }
  .button.alert {
    background-color: #e74436;
    color: #000000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #cc2718;
      color: #000000; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #012c9f;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #012c9f;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #008a00;
        color: #000000; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #008a00;
        color: #000000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffc800;
        color: #000000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #e74436;
        color: #000000; }
  .button.hollow {
    border: 0.5px solid #012c9f;
    color: #012c9f; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #011650;
      color: #011650; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 0.5px solid #012c9f;
        color: #012c9f; }
    .button.hollow.primary {
      border: 0.5px solid #012c9f;
      color: #012c9f; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #011650;
        color: #011650; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 0.5px solid #012c9f;
          color: #012c9f; }
    .button.hollow.secondary {
      border: 0.5px solid #008a00;
      color: #008a00; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #004500;
        color: #004500; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 0.5px solid #008a00;
          color: #008a00; }
    .button.hollow.success {
      border: 0.5px solid #008a00;
      color: #008a00; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #004500;
        color: #004500; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 0.5px solid #008a00;
          color: #008a00; }
    .button.hollow.warning {
      border: 0.5px solid #ffc800;
      color: #ffc800; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #806400;
        color: #806400; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 0.5px solid #ffc800;
          color: #ffc800; }
    .button.hollow.alert {
      border: 0.5px solid #e74436;
      color: #e74436; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #7f180f;
        color: #7f180f; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 0.5px solid #e74436;
          color: #e74436; }
  .button.clear {
    border: 0.5px solid #012c9f;
    color: #012c9f; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #011650;
      color: #011650; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 0.5px solid #012c9f;
        color: #012c9f; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 0.5px solid #012c9f;
      color: #012c9f; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #011650;
        color: #011650; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 0.5px solid #012c9f;
          color: #012c9f; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 0.5px solid #008a00;
      color: #008a00; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #004500;
        color: #004500; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 0.5px solid #008a00;
          color: #008a00; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 0.5px solid #008a00;
      color: #008a00; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #004500;
        color: #004500; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 0.5px solid #008a00;
          color: #008a00; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 0.5px solid #ffc800;
      color: #ffc800; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #806400;
        color: #806400; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 0.5px solid #ffc800;
          color: #ffc800; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 0.5px solid #e74436;
      color: #e74436; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #7f180f;
        color: #7f180f; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 0.5px solid #e74436;
          color: #e74436; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 2em; }
  .button.dropdown.hollow::after {
    border-top-color: #012c9f; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #012c9f; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #008a00; }
  .button.dropdown.hollow.success::after {
    border-top-color: #008a00; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffc800; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #e74436; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1.47619rem;
  margin: 0 0 0.7619rem;
  padding: 0.38095rem;
  border: 1px solid #A1A1A1;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 0.7619rem;
  font-weight: normal;
  line-height: 1;
  color: #000000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #A1A1A1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #A1A1A1; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #f4f4f4;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 0.7619rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.38095rem;
  margin-right: 0.7619rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.38095rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.66667rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000000; }
  label.middle {
    margin: 0 0 0.7619rem;
    padding: 0.42857rem 0; }

.help-text {
  margin-top: -0.38095rem;
  font-size: 0.61905rem;
  font-style: italic;
  color: #000000; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 0.7619rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #A1A1A1;
  background: #f4f4f4;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.7619rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.38095rem; }

.fieldset {
  margin: 0.85714rem 0;
  padding: 0.95238rem;
  border: 1px solid #A1A1A1; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.14286rem;
    padding: 0 0.14286rem; }

select {
  height: 1.47619rem;
  margin: 0 0 0.7619rem;
  padding: 0.38095rem;
  appearance: none;
  border: 1px solid #A1A1A1;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 0.7619rem;
  font-weight: normal;
  line-height: 1;
  color: #000000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -0.7619rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.14286rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #A1A1A1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #f4f4f4;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #e74436;
  background-color: #fcebea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #e74436; }

.is-invalid-label {
  color: #e74436; }

.form-error {
  display: none;
  margin-top: -0.38095rem;
  margin-bottom: 0.7619rem;
  font-size: 0.57143rem;
  font-weight: bold;
  color: #e74436; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #012c9f;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #01237f;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #008a00;
    color: #000000; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #006e00;
      color: #000000; }
  .button-group.success .button {
    background-color: #008a00;
    color: #000000; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #006e00;
      color: #000000; }
  .button-group.warning .button {
    background-color: #ffc800;
    color: #000000; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cca000;
      color: #000000; }
  .button-group.alert .button {
    background-color: #e74436;
    color: #000000; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #cc2718;
      color: #000000; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 96.875em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 53.0625em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0.5rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 53.125em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 96.875em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #012c9f;
    color: #fefefe; }
  .menu .active > a {
    background: #012c9f;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #012c9f transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.5rem 0.5rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #012c9f; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #012c9f transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #012c9f; }

@media print, screen and (min-width: 53.125em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #012c9f transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012c9f transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012c9f; } }

@media print, screen and (min-width: 96.875em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #012c9f transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012c9f transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012c9f; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #A1A1A1;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.5rem 0.5rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #012c9f transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #012c9f; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(138, 138, 138, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1000;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f4f4f4; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 1000; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 1001; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 0px rgba(0, 0, 0, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1000;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #f4f4f4; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 1000; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 1001; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 0px rgba(0, 0, 0, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  transform: translateX(-100%); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-100%); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(100%); }
  .position-left.is-transition-push {
    box-shadow: inset 0px 0 0px 0px rgba(0, 0, 0, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  transform: translateX(100%); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(100%); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-100%); }
  .position-right.is-transition-push {
    box-shadow: inset 0px 0 0px 0px rgba(0, 0, 0, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 0px 0px 0px rgba(0, 0, 0, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 0px 0px 0px rgba(0, 0, 0, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 53.125em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 100%; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 100%; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 100%; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 100%; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 100%; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 100%; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 100%; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 100%; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 96.875em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: tranparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 96.8125em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 53.0625em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 53.125em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 53.125em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 53.0625em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 53.125em) and (max-width: 96.8125em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 53.0625em), screen and (min-width: 96.875em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 96.875em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 96.8125em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 96.875em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 96.8125em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 53.125em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 96.875em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 53.125em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 96.875em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-ccw-1turn; }

@keyframes spin-ccw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate(0, 0); }

[data-aos='fade-up'] {
  transform: translate(0, 100px); }

[data-aos='fade-down'] {
  transform: translate(0, -100px); }

[data-aos='fade-right'] {
  transform: translate(-100px, 0); }

[data-aos='fade-left'] {
  transform: translate(100px, 0); }

[data-aos='fade-up-right'] {
  transform: translate(-100px, 100px); }

[data-aos='fade-up-left'] {
  transform: translate(100px, 100px); }

[data-aos='fade-down-right'] {
  transform: translate(-100px, -100px); }

[data-aos='fade-down-left'] {
  transform: translate(100px, -100px); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate(0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate(0, 100px) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate(0, -100px) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate(-100px, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate(100px, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate(0, 100px) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate(0, -100px) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate(-100px, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate(100px, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate(0, 0); }

[data-aos='slide-up'] {
  transform: translateY(100%); }

[data-aos='slide-down'] {
  transform: translateY(-100%); }

[data-aos='slide-right'] {
  transform: translateX(-100%); }

[data-aos='slide-left'] {
  transform: translateX(100%); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

.slick-dotted.slick-slider {
  padding-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.index-menu {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .index-menu .menu a {
    text-transform: uppercase;
    color: #2e2e2e;
    transition: color 0.2s ease-in; }
    .index-menu .menu a:hover {
      color: #012c9f; }

.small-margin-top-0 {
  margin-top: 0; }

.small-margin-top-1 {
  margin-top: 0.38095rem; }

.small-margin-top-2 {
  margin-top: 0.7619rem; }

.small-margin-top-3 {
  margin-top: 1.14286rem; }

.small-margin-top-4 {
  margin-top: 1.52381rem; }

.small-margin-top-5 {
  margin-top: 1.90476rem; }

.small-margin-top-6 {
  margin-top: 2.28571rem; }

.small-margin-top-7 {
  margin-top: 2.66667rem; }

.small-margin-top-8 {
  margin-top: 3.04762rem; }

.small-margin-top-9 {
  margin-top: 3.42857rem; }

.small-margin-top-10 {
  margin-top: 3.80952rem; }

.small-margin-top-11 {
  margin-top: 4.19048rem; }

.small-margin-top-12 {
  margin-top: 4.57143rem; }

.small-margin-top-13 {
  margin-top: 4.95238rem; }

.small-margin-top-14 {
  margin-top: 5.33333rem; }

.small-margin-top-15 {
  margin-top: 5.71429rem; }

.small-margin-top-16 {
  margin-top: 6.09524rem; }

.small-margin-top-17 {
  margin-top: 6.47619rem; }

.small-margin-top-18 {
  margin-top: 6.85714rem; }

.small-margin-top-19 {
  margin-top: 7.2381rem; }

.small-margin-top-20 {
  margin-top: 7.61905rem; }

.small-margin-top-21 {
  margin-top: 8rem; }

.small-margin-top-22 {
  margin-top: 8.38095rem; }

.small-margin-top-23 {
  margin-top: 8.7619rem; }

.small-margin-top-24 {
  margin-top: 9.14286rem; }

.small-margin-top-25 {
  margin-top: 9.52381rem; }

.small-margin-top-26 {
  margin-top: 9.90476rem; }

.small-margin-top-27 {
  margin-top: 10.28571rem; }

.small-margin-top-28 {
  margin-top: 10.66667rem; }

.small-margin-top-29 {
  margin-top: 11.04762rem; }

.small-margin-top-30 {
  margin-top: 11.42857rem; }

.small-margin-top-31 {
  margin-top: 11.80952rem; }

.small-margin-top-32 {
  margin-top: 12.19048rem; }

.small-margin-top-33 {
  margin-top: 12.57143rem; }

.small-margin-top-34 {
  margin-top: 12.95238rem; }

.small-margin-top-35 {
  margin-top: 13.33333rem; }

.small-margin-top-36 {
  margin-top: 13.71429rem; }

.small-margin-top-37 {
  margin-top: 14.09524rem; }

.small-margin-top-38 {
  margin-top: 14.47619rem; }

.small-margin-top-39 {
  margin-top: 14.85714rem; }

.small-margin-top-40 {
  margin-top: 15.2381rem; }

.small-margin-top-41 {
  margin-top: 15.61905rem; }

.small-margin-top-42 {
  margin-top: 16rem; }

.small-margin-top-43 {
  margin-top: 16.38095rem; }

.small-margin-top-44 {
  margin-top: 16.7619rem; }

.small-margin-top-45 {
  margin-top: 17.14286rem; }

.small-margin-top-46 {
  margin-top: 17.52381rem; }

.small-margin-top-47 {
  margin-top: 17.90476rem; }

.small-margin-top-48 {
  margin-top: 18.28571rem; }

.small-margin-bottom-0 {
  margin-bottom: 0; }

.small-margin-bottom-1 {
  margin-bottom: 0.38095rem; }

.small-margin-bottom-2 {
  margin-bottom: 0.7619rem; }

.small-margin-bottom-3 {
  margin-bottom: 1.14286rem; }

.small-margin-bottom-4 {
  margin-bottom: 1.52381rem; }

.small-margin-bottom-5 {
  margin-bottom: 1.90476rem; }

.small-margin-bottom-6 {
  margin-bottom: 2.28571rem; }

.small-margin-bottom-7 {
  margin-bottom: 2.66667rem; }

.small-margin-bottom-8 {
  margin-bottom: 3.04762rem; }

.small-margin-bottom-9 {
  margin-bottom: 3.42857rem; }

.small-margin-bottom-10 {
  margin-bottom: 3.80952rem; }

.small-margin-bottom-11 {
  margin-bottom: 4.19048rem; }

.small-margin-bottom-12 {
  margin-bottom: 4.57143rem; }

.small-margin-bottom-13 {
  margin-bottom: 4.95238rem; }

.small-margin-bottom-14 {
  margin-bottom: 5.33333rem; }

.small-margin-bottom-15 {
  margin-bottom: 5.71429rem; }

.small-margin-bottom-16 {
  margin-bottom: 6.09524rem; }

.small-margin-bottom-17 {
  margin-bottom: 6.47619rem; }

.small-margin-bottom-18 {
  margin-bottom: 6.85714rem; }

.small-margin-bottom-19 {
  margin-bottom: 7.2381rem; }

.small-margin-bottom-20 {
  margin-bottom: 7.61905rem; }

.small-margin-bottom-21 {
  margin-bottom: 8rem; }

.small-margin-bottom-22 {
  margin-bottom: 8.38095rem; }

.small-margin-bottom-23 {
  margin-bottom: 8.7619rem; }

.small-margin-bottom-24 {
  margin-bottom: 9.14286rem; }

.small-margin-bottom-25 {
  margin-bottom: 9.52381rem; }

.small-margin-bottom-26 {
  margin-bottom: 9.90476rem; }

.small-margin-bottom-27 {
  margin-bottom: 10.28571rem; }

.small-margin-bottom-28 {
  margin-bottom: 10.66667rem; }

.small-margin-bottom-29 {
  margin-bottom: 11.04762rem; }

.small-margin-bottom-30 {
  margin-bottom: 11.42857rem; }

.small-margin-bottom-31 {
  margin-bottom: 11.80952rem; }

.small-margin-bottom-32 {
  margin-bottom: 12.19048rem; }

.small-margin-bottom-33 {
  margin-bottom: 12.57143rem; }

.small-margin-bottom-34 {
  margin-bottom: 12.95238rem; }

.small-margin-bottom-35 {
  margin-bottom: 13.33333rem; }

.small-margin-bottom-36 {
  margin-bottom: 13.71429rem; }

.small-margin-bottom-37 {
  margin-bottom: 14.09524rem; }

.small-margin-bottom-38 {
  margin-bottom: 14.47619rem; }

.small-margin-bottom-39 {
  margin-bottom: 14.85714rem; }

.small-margin-bottom-40 {
  margin-bottom: 15.2381rem; }

.small-margin-bottom-41 {
  margin-bottom: 15.61905rem; }

.small-margin-bottom-42 {
  margin-bottom: 16rem; }

.small-margin-bottom-43 {
  margin-bottom: 16.38095rem; }

.small-margin-bottom-44 {
  margin-bottom: 16.7619rem; }

.small-margin-bottom-45 {
  margin-bottom: 17.14286rem; }

.small-margin-bottom-46 {
  margin-bottom: 17.52381rem; }

.small-margin-bottom-47 {
  margin-bottom: 17.90476rem; }

.small-margin-bottom-48 {
  margin-bottom: 18.28571rem; }

.small-margin-right-0 {
  margin-right: 0; }

.small-margin-right-1 {
  margin-right: 0.38095rem; }

.small-margin-right-2 {
  margin-right: 0.7619rem; }

.small-margin-right-3 {
  margin-right: 1.14286rem; }

.small-margin-right-4 {
  margin-right: 1.52381rem; }

.small-margin-right-5 {
  margin-right: 1.90476rem; }

.small-margin-right-6 {
  margin-right: 2.28571rem; }

.small-margin-right-7 {
  margin-right: 2.66667rem; }

.small-margin-right-8 {
  margin-right: 3.04762rem; }

.small-margin-right-9 {
  margin-right: 3.42857rem; }

.small-margin-right-10 {
  margin-right: 3.80952rem; }

.small-margin-right-11 {
  margin-right: 4.19048rem; }

.small-margin-right-12 {
  margin-right: 4.57143rem; }

.small-margin-right-13 {
  margin-right: 4.95238rem; }

.small-margin-right-14 {
  margin-right: 5.33333rem; }

.small-margin-right-15 {
  margin-right: 5.71429rem; }

.small-margin-right-16 {
  margin-right: 6.09524rem; }

.small-margin-right-17 {
  margin-right: 6.47619rem; }

.small-margin-right-18 {
  margin-right: 6.85714rem; }

.small-margin-right-19 {
  margin-right: 7.2381rem; }

.small-margin-right-20 {
  margin-right: 7.61905rem; }

.small-margin-right-21 {
  margin-right: 8rem; }

.small-margin-right-22 {
  margin-right: 8.38095rem; }

.small-margin-right-23 {
  margin-right: 8.7619rem; }

.small-margin-right-24 {
  margin-right: 9.14286rem; }

.small-margin-right-25 {
  margin-right: 9.52381rem; }

.small-margin-right-26 {
  margin-right: 9.90476rem; }

.small-margin-right-27 {
  margin-right: 10.28571rem; }

.small-margin-right-28 {
  margin-right: 10.66667rem; }

.small-margin-right-29 {
  margin-right: 11.04762rem; }

.small-margin-right-30 {
  margin-right: 11.42857rem; }

.small-margin-right-31 {
  margin-right: 11.80952rem; }

.small-margin-right-32 {
  margin-right: 12.19048rem; }

.small-margin-right-33 {
  margin-right: 12.57143rem; }

.small-margin-right-34 {
  margin-right: 12.95238rem; }

.small-margin-right-35 {
  margin-right: 13.33333rem; }

.small-margin-right-36 {
  margin-right: 13.71429rem; }

.small-margin-right-37 {
  margin-right: 14.09524rem; }

.small-margin-right-38 {
  margin-right: 14.47619rem; }

.small-margin-right-39 {
  margin-right: 14.85714rem; }

.small-margin-right-40 {
  margin-right: 15.2381rem; }

.small-margin-right-41 {
  margin-right: 15.61905rem; }

.small-margin-right-42 {
  margin-right: 16rem; }

.small-margin-right-43 {
  margin-right: 16.38095rem; }

.small-margin-right-44 {
  margin-right: 16.7619rem; }

.small-margin-right-45 {
  margin-right: 17.14286rem; }

.small-margin-right-46 {
  margin-right: 17.52381rem; }

.small-margin-right-47 {
  margin-right: 17.90476rem; }

.small-margin-right-48 {
  margin-right: 18.28571rem; }

.small-margin-left-0 {
  margin-left: 0; }

.small-margin-left-1 {
  margin-left: 0.38095rem; }

.small-margin-left-2 {
  margin-left: 0.7619rem; }

.small-margin-left-3 {
  margin-left: 1.14286rem; }

.small-margin-left-4 {
  margin-left: 1.52381rem; }

.small-margin-left-5 {
  margin-left: 1.90476rem; }

.small-margin-left-6 {
  margin-left: 2.28571rem; }

.small-margin-left-7 {
  margin-left: 2.66667rem; }

.small-margin-left-8 {
  margin-left: 3.04762rem; }

.small-margin-left-9 {
  margin-left: 3.42857rem; }

.small-margin-left-10 {
  margin-left: 3.80952rem; }

.small-margin-left-11 {
  margin-left: 4.19048rem; }

.small-margin-left-12 {
  margin-left: 4.57143rem; }

.small-margin-left-13 {
  margin-left: 4.95238rem; }

.small-margin-left-14 {
  margin-left: 5.33333rem; }

.small-margin-left-15 {
  margin-left: 5.71429rem; }

.small-margin-left-16 {
  margin-left: 6.09524rem; }

.small-margin-left-17 {
  margin-left: 6.47619rem; }

.small-margin-left-18 {
  margin-left: 6.85714rem; }

.small-margin-left-19 {
  margin-left: 7.2381rem; }

.small-margin-left-20 {
  margin-left: 7.61905rem; }

.small-margin-left-21 {
  margin-left: 8rem; }

.small-margin-left-22 {
  margin-left: 8.38095rem; }

.small-margin-left-23 {
  margin-left: 8.7619rem; }

.small-margin-left-24 {
  margin-left: 9.14286rem; }

.small-margin-left-25 {
  margin-left: 9.52381rem; }

.small-margin-left-26 {
  margin-left: 9.90476rem; }

.small-margin-left-27 {
  margin-left: 10.28571rem; }

.small-margin-left-28 {
  margin-left: 10.66667rem; }

.small-margin-left-29 {
  margin-left: 11.04762rem; }

.small-margin-left-30 {
  margin-left: 11.42857rem; }

.small-margin-left-31 {
  margin-left: 11.80952rem; }

.small-margin-left-32 {
  margin-left: 12.19048rem; }

.small-margin-left-33 {
  margin-left: 12.57143rem; }

.small-margin-left-34 {
  margin-left: 12.95238rem; }

.small-margin-left-35 {
  margin-left: 13.33333rem; }

.small-margin-left-36 {
  margin-left: 13.71429rem; }

.small-margin-left-37 {
  margin-left: 14.09524rem; }

.small-margin-left-38 {
  margin-left: 14.47619rem; }

.small-margin-left-39 {
  margin-left: 14.85714rem; }

.small-margin-left-40 {
  margin-left: 15.2381rem; }

.small-margin-left-41 {
  margin-left: 15.61905rem; }

.small-margin-left-42 {
  margin-left: 16rem; }

.small-margin-left-43 {
  margin-left: 16.38095rem; }

.small-margin-left-44 {
  margin-left: 16.7619rem; }

.small-margin-left-45 {
  margin-left: 17.14286rem; }

.small-margin-left-46 {
  margin-left: 17.52381rem; }

.small-margin-left-47 {
  margin-left: 17.90476rem; }

.small-margin-left-48 {
  margin-left: 18.28571rem; }

.small-margin-0 {
  margin-top: 0;
  margin-bottom: 0; }

.small-margin-1 {
  margin-top: 0.38095rem;
  margin-bottom: 0.38095rem; }

.small-margin-2 {
  margin-top: 0.7619rem;
  margin-bottom: 0.7619rem; }

.small-margin-3 {
  margin-top: 1.14286rem;
  margin-bottom: 1.14286rem; }

.small-margin-4 {
  margin-top: 1.52381rem;
  margin-bottom: 1.52381rem; }

.small-margin-5 {
  margin-top: 1.90476rem;
  margin-bottom: 1.90476rem; }

.small-margin-6 {
  margin-top: 2.28571rem;
  margin-bottom: 2.28571rem; }

.small-margin-7 {
  margin-top: 2.66667rem;
  margin-bottom: 2.66667rem; }

.small-margin-8 {
  margin-top: 3.04762rem;
  margin-bottom: 3.04762rem; }

.small-margin-9 {
  margin-top: 3.42857rem;
  margin-bottom: 3.42857rem; }

.small-margin-10 {
  margin-top: 3.80952rem;
  margin-bottom: 3.80952rem; }

.small-margin-11 {
  margin-top: 4.19048rem;
  margin-bottom: 4.19048rem; }

.small-margin-12 {
  margin-top: 4.57143rem;
  margin-bottom: 4.57143rem; }

.small-margin-13 {
  margin-top: 4.95238rem;
  margin-bottom: 4.95238rem; }

.small-margin-14 {
  margin-top: 5.33333rem;
  margin-bottom: 5.33333rem; }

.small-margin-15 {
  margin-top: 5.71429rem;
  margin-bottom: 5.71429rem; }

.small-margin-16 {
  margin-top: 6.09524rem;
  margin-bottom: 6.09524rem; }

.small-margin-17 {
  margin-top: 6.47619rem;
  margin-bottom: 6.47619rem; }

.small-margin-18 {
  margin-top: 6.85714rem;
  margin-bottom: 6.85714rem; }

.small-margin-19 {
  margin-top: 7.2381rem;
  margin-bottom: 7.2381rem; }

.small-margin-20 {
  margin-top: 7.61905rem;
  margin-bottom: 7.61905rem; }

.small-margin-21 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.small-margin-22 {
  margin-top: 8.38095rem;
  margin-bottom: 8.38095rem; }

.small-margin-23 {
  margin-top: 8.7619rem;
  margin-bottom: 8.7619rem; }

.small-margin-24 {
  margin-top: 9.14286rem;
  margin-bottom: 9.14286rem; }

.small-margin-25 {
  margin-top: 9.52381rem;
  margin-bottom: 9.52381rem; }

.small-margin-26 {
  margin-top: 9.90476rem;
  margin-bottom: 9.90476rem; }

.small-margin-27 {
  margin-top: 10.28571rem;
  margin-bottom: 10.28571rem; }

.small-margin-28 {
  margin-top: 10.66667rem;
  margin-bottom: 10.66667rem; }

.small-margin-29 {
  margin-top: 11.04762rem;
  margin-bottom: 11.04762rem; }

.small-margin-30 {
  margin-top: 11.42857rem;
  margin-bottom: 11.42857rem; }

.small-margin-31 {
  margin-top: 11.80952rem;
  margin-bottom: 11.80952rem; }

.small-margin-32 {
  margin-top: 12.19048rem;
  margin-bottom: 12.19048rem; }

.small-margin-33 {
  margin-top: 12.57143rem;
  margin-bottom: 12.57143rem; }

.small-margin-34 {
  margin-top: 12.95238rem;
  margin-bottom: 12.95238rem; }

.small-margin-35 {
  margin-top: 13.33333rem;
  margin-bottom: 13.33333rem; }

.small-margin-36 {
  margin-top: 13.71429rem;
  margin-bottom: 13.71429rem; }

.small-margin-37 {
  margin-top: 14.09524rem;
  margin-bottom: 14.09524rem; }

.small-margin-38 {
  margin-top: 14.47619rem;
  margin-bottom: 14.47619rem; }

.small-margin-39 {
  margin-top: 14.85714rem;
  margin-bottom: 14.85714rem; }

.small-margin-40 {
  margin-top: 15.2381rem;
  margin-bottom: 15.2381rem; }

.small-margin-41 {
  margin-top: 15.61905rem;
  margin-bottom: 15.61905rem; }

.small-margin-42 {
  margin-top: 16rem;
  margin-bottom: 16rem; }

.small-margin-43 {
  margin-top: 16.38095rem;
  margin-bottom: 16.38095rem; }

.small-margin-44 {
  margin-top: 16.7619rem;
  margin-bottom: 16.7619rem; }

.small-margin-45 {
  margin-top: 17.14286rem;
  margin-bottom: 17.14286rem; }

.small-margin-46 {
  margin-top: 17.52381rem;
  margin-bottom: 17.52381rem; }

.small-margin-47 {
  margin-top: 17.90476rem;
  margin-bottom: 17.90476rem; }

.small-margin-48 {
  margin-top: 18.28571rem;
  margin-bottom: 18.28571rem; }

.small-padding-top-0 {
  padding-top: 0; }

.small-padding-top-1 {
  padding-top: 0.38095rem; }

.small-padding-top-2 {
  padding-top: 0.7619rem; }

.small-padding-top-3 {
  padding-top: 1.14286rem; }

.small-padding-top-4 {
  padding-top: 1.52381rem; }

.small-padding-top-5 {
  padding-top: 1.90476rem; }

.small-padding-top-6 {
  padding-top: 2.28571rem; }

.small-padding-top-7 {
  padding-top: 2.66667rem; }

.small-padding-top-8 {
  padding-top: 3.04762rem; }

.small-padding-top-9 {
  padding-top: 3.42857rem; }

.small-padding-top-10 {
  padding-top: 3.80952rem; }

.small-padding-top-11 {
  padding-top: 4.19048rem; }

.small-padding-top-12 {
  padding-top: 4.57143rem; }

.small-padding-top-13 {
  padding-top: 4.95238rem; }

.small-padding-top-14 {
  padding-top: 5.33333rem; }

.small-padding-top-15 {
  padding-top: 5.71429rem; }

.small-padding-top-16 {
  padding-top: 6.09524rem; }

.small-padding-top-17 {
  padding-top: 6.47619rem; }

.small-padding-top-18 {
  padding-top: 6.85714rem; }

.small-padding-top-19 {
  padding-top: 7.2381rem; }

.small-padding-top-20 {
  padding-top: 7.61905rem; }

.small-padding-top-21 {
  padding-top: 8rem; }

.small-padding-top-22 {
  padding-top: 8.38095rem; }

.small-padding-top-23 {
  padding-top: 8.7619rem; }

.small-padding-top-24 {
  padding-top: 9.14286rem; }

.small-padding-top-25 {
  padding-top: 9.52381rem; }

.small-padding-top-26 {
  padding-top: 9.90476rem; }

.small-padding-top-27 {
  padding-top: 10.28571rem; }

.small-padding-top-28 {
  padding-top: 10.66667rem; }

.small-padding-top-29 {
  padding-top: 11.04762rem; }

.small-padding-top-30 {
  padding-top: 11.42857rem; }

.small-padding-top-31 {
  padding-top: 11.80952rem; }

.small-padding-top-32 {
  padding-top: 12.19048rem; }

.small-padding-top-33 {
  padding-top: 12.57143rem; }

.small-padding-top-34 {
  padding-top: 12.95238rem; }

.small-padding-top-35 {
  padding-top: 13.33333rem; }

.small-padding-top-36 {
  padding-top: 13.71429rem; }

.small-padding-top-37 {
  padding-top: 14.09524rem; }

.small-padding-top-38 {
  padding-top: 14.47619rem; }

.small-padding-top-39 {
  padding-top: 14.85714rem; }

.small-padding-top-40 {
  padding-top: 15.2381rem; }

.small-padding-top-41 {
  padding-top: 15.61905rem; }

.small-padding-top-42 {
  padding-top: 16rem; }

.small-padding-top-43 {
  padding-top: 16.38095rem; }

.small-padding-top-44 {
  padding-top: 16.7619rem; }

.small-padding-top-45 {
  padding-top: 17.14286rem; }

.small-padding-top-46 {
  padding-top: 17.52381rem; }

.small-padding-top-47 {
  padding-top: 17.90476rem; }

.small-padding-top-48 {
  padding-top: 18.28571rem; }

.small-padding-bottom-0 {
  padding-bottom: 0; }

.small-padding-bottom-1 {
  padding-bottom: 0.38095rem; }

.small-padding-bottom-2 {
  padding-bottom: 0.7619rem; }

.small-padding-bottom-3 {
  padding-bottom: 1.14286rem; }

.small-padding-bottom-4 {
  padding-bottom: 1.52381rem; }

.small-padding-bottom-5 {
  padding-bottom: 1.90476rem; }

.small-padding-bottom-6 {
  padding-bottom: 2.28571rem; }

.small-padding-bottom-7 {
  padding-bottom: 2.66667rem; }

.small-padding-bottom-8 {
  padding-bottom: 3.04762rem; }

.small-padding-bottom-9 {
  padding-bottom: 3.42857rem; }

.small-padding-bottom-10 {
  padding-bottom: 3.80952rem; }

.small-padding-bottom-11 {
  padding-bottom: 4.19048rem; }

.small-padding-bottom-12 {
  padding-bottom: 4.57143rem; }

.small-padding-bottom-13 {
  padding-bottom: 4.95238rem; }

.small-padding-bottom-14 {
  padding-bottom: 5.33333rem; }

.small-padding-bottom-15 {
  padding-bottom: 5.71429rem; }

.small-padding-bottom-16 {
  padding-bottom: 6.09524rem; }

.small-padding-bottom-17 {
  padding-bottom: 6.47619rem; }

.small-padding-bottom-18 {
  padding-bottom: 6.85714rem; }

.small-padding-bottom-19 {
  padding-bottom: 7.2381rem; }

.small-padding-bottom-20 {
  padding-bottom: 7.61905rem; }

.small-padding-bottom-21 {
  padding-bottom: 8rem; }

.small-padding-bottom-22 {
  padding-bottom: 8.38095rem; }

.small-padding-bottom-23 {
  padding-bottom: 8.7619rem; }

.small-padding-bottom-24 {
  padding-bottom: 9.14286rem; }

.small-padding-bottom-25 {
  padding-bottom: 9.52381rem; }

.small-padding-bottom-26 {
  padding-bottom: 9.90476rem; }

.small-padding-bottom-27 {
  padding-bottom: 10.28571rem; }

.small-padding-bottom-28 {
  padding-bottom: 10.66667rem; }

.small-padding-bottom-29 {
  padding-bottom: 11.04762rem; }

.small-padding-bottom-30 {
  padding-bottom: 11.42857rem; }

.small-padding-bottom-31 {
  padding-bottom: 11.80952rem; }

.small-padding-bottom-32 {
  padding-bottom: 12.19048rem; }

.small-padding-bottom-33 {
  padding-bottom: 12.57143rem; }

.small-padding-bottom-34 {
  padding-bottom: 12.95238rem; }

.small-padding-bottom-35 {
  padding-bottom: 13.33333rem; }

.small-padding-bottom-36 {
  padding-bottom: 13.71429rem; }

.small-padding-bottom-37 {
  padding-bottom: 14.09524rem; }

.small-padding-bottom-38 {
  padding-bottom: 14.47619rem; }

.small-padding-bottom-39 {
  padding-bottom: 14.85714rem; }

.small-padding-bottom-40 {
  padding-bottom: 15.2381rem; }

.small-padding-bottom-41 {
  padding-bottom: 15.61905rem; }

.small-padding-bottom-42 {
  padding-bottom: 16rem; }

.small-padding-bottom-43 {
  padding-bottom: 16.38095rem; }

.small-padding-bottom-44 {
  padding-bottom: 16.7619rem; }

.small-padding-bottom-45 {
  padding-bottom: 17.14286rem; }

.small-padding-bottom-46 {
  padding-bottom: 17.52381rem; }

.small-padding-bottom-47 {
  padding-bottom: 17.90476rem; }

.small-padding-bottom-48 {
  padding-bottom: 18.28571rem; }

.small-padding-right-0 {
  padding-right: 0; }

.small-padding-right-1 {
  padding-right: 0.38095rem; }

.small-padding-right-2 {
  padding-right: 0.7619rem; }

.small-padding-right-3 {
  padding-right: 1.14286rem; }

.small-padding-right-4 {
  padding-right: 1.52381rem; }

.small-padding-right-5 {
  padding-right: 1.90476rem; }

.small-padding-right-6 {
  padding-right: 2.28571rem; }

.small-padding-right-7 {
  padding-right: 2.66667rem; }

.small-padding-right-8 {
  padding-right: 3.04762rem; }

.small-padding-right-9 {
  padding-right: 3.42857rem; }

.small-padding-right-10 {
  padding-right: 3.80952rem; }

.small-padding-right-11 {
  padding-right: 4.19048rem; }

.small-padding-right-12 {
  padding-right: 4.57143rem; }

.small-padding-right-13 {
  padding-right: 4.95238rem; }

.small-padding-right-14 {
  padding-right: 5.33333rem; }

.small-padding-right-15 {
  padding-right: 5.71429rem; }

.small-padding-right-16 {
  padding-right: 6.09524rem; }

.small-padding-right-17 {
  padding-right: 6.47619rem; }

.small-padding-right-18 {
  padding-right: 6.85714rem; }

.small-padding-right-19 {
  padding-right: 7.2381rem; }

.small-padding-right-20 {
  padding-right: 7.61905rem; }

.small-padding-right-21 {
  padding-right: 8rem; }

.small-padding-right-22 {
  padding-right: 8.38095rem; }

.small-padding-right-23 {
  padding-right: 8.7619rem; }

.small-padding-right-24 {
  padding-right: 9.14286rem; }

.small-padding-right-25 {
  padding-right: 9.52381rem; }

.small-padding-right-26 {
  padding-right: 9.90476rem; }

.small-padding-right-27 {
  padding-right: 10.28571rem; }

.small-padding-right-28 {
  padding-right: 10.66667rem; }

.small-padding-right-29 {
  padding-right: 11.04762rem; }

.small-padding-right-30 {
  padding-right: 11.42857rem; }

.small-padding-right-31 {
  padding-right: 11.80952rem; }

.small-padding-right-32 {
  padding-right: 12.19048rem; }

.small-padding-right-33 {
  padding-right: 12.57143rem; }

.small-padding-right-34 {
  padding-right: 12.95238rem; }

.small-padding-right-35 {
  padding-right: 13.33333rem; }

.small-padding-right-36 {
  padding-right: 13.71429rem; }

.small-padding-right-37 {
  padding-right: 14.09524rem; }

.small-padding-right-38 {
  padding-right: 14.47619rem; }

.small-padding-right-39 {
  padding-right: 14.85714rem; }

.small-padding-right-40 {
  padding-right: 15.2381rem; }

.small-padding-right-41 {
  padding-right: 15.61905rem; }

.small-padding-right-42 {
  padding-right: 16rem; }

.small-padding-right-43 {
  padding-right: 16.38095rem; }

.small-padding-right-44 {
  padding-right: 16.7619rem; }

.small-padding-right-45 {
  padding-right: 17.14286rem; }

.small-padding-right-46 {
  padding-right: 17.52381rem; }

.small-padding-right-47 {
  padding-right: 17.90476rem; }

.small-padding-right-48 {
  padding-right: 18.28571rem; }

.small-padding-left-0 {
  padding-left: 0; }

.small-padding-left-1 {
  padding-left: 0.38095rem; }

.small-padding-left-2 {
  padding-left: 0.7619rem; }

.small-padding-left-3 {
  padding-left: 1.14286rem; }

.small-padding-left-4 {
  padding-left: 1.52381rem; }

.small-padding-left-5 {
  padding-left: 1.90476rem; }

.small-padding-left-6 {
  padding-left: 2.28571rem; }

.small-padding-left-7 {
  padding-left: 2.66667rem; }

.small-padding-left-8 {
  padding-left: 3.04762rem; }

.small-padding-left-9 {
  padding-left: 3.42857rem; }

.small-padding-left-10 {
  padding-left: 3.80952rem; }

.small-padding-left-11 {
  padding-left: 4.19048rem; }

.small-padding-left-12 {
  padding-left: 4.57143rem; }

.small-padding-left-13 {
  padding-left: 4.95238rem; }

.small-padding-left-14 {
  padding-left: 5.33333rem; }

.small-padding-left-15 {
  padding-left: 5.71429rem; }

.small-padding-left-16 {
  padding-left: 6.09524rem; }

.small-padding-left-17 {
  padding-left: 6.47619rem; }

.small-padding-left-18 {
  padding-left: 6.85714rem; }

.small-padding-left-19 {
  padding-left: 7.2381rem; }

.small-padding-left-20 {
  padding-left: 7.61905rem; }

.small-padding-left-21 {
  padding-left: 8rem; }

.small-padding-left-22 {
  padding-left: 8.38095rem; }

.small-padding-left-23 {
  padding-left: 8.7619rem; }

.small-padding-left-24 {
  padding-left: 9.14286rem; }

.small-padding-left-25 {
  padding-left: 9.52381rem; }

.small-padding-left-26 {
  padding-left: 9.90476rem; }

.small-padding-left-27 {
  padding-left: 10.28571rem; }

.small-padding-left-28 {
  padding-left: 10.66667rem; }

.small-padding-left-29 {
  padding-left: 11.04762rem; }

.small-padding-left-30 {
  padding-left: 11.42857rem; }

.small-padding-left-31 {
  padding-left: 11.80952rem; }

.small-padding-left-32 {
  padding-left: 12.19048rem; }

.small-padding-left-33 {
  padding-left: 12.57143rem; }

.small-padding-left-34 {
  padding-left: 12.95238rem; }

.small-padding-left-35 {
  padding-left: 13.33333rem; }

.small-padding-left-36 {
  padding-left: 13.71429rem; }

.small-padding-left-37 {
  padding-left: 14.09524rem; }

.small-padding-left-38 {
  padding-left: 14.47619rem; }

.small-padding-left-39 {
  padding-left: 14.85714rem; }

.small-padding-left-40 {
  padding-left: 15.2381rem; }

.small-padding-left-41 {
  padding-left: 15.61905rem; }

.small-padding-left-42 {
  padding-left: 16rem; }

.small-padding-left-43 {
  padding-left: 16.38095rem; }

.small-padding-left-44 {
  padding-left: 16.7619rem; }

.small-padding-left-45 {
  padding-left: 17.14286rem; }

.small-padding-left-46 {
  padding-left: 17.52381rem; }

.small-padding-left-47 {
  padding-left: 17.90476rem; }

.small-padding-left-48 {
  padding-left: 18.28571rem; }

.small-padding-0 {
  padding-top: 0;
  padding-bottom: 0; }

.small-padding-1 {
  padding-top: 0.38095rem;
  padding-bottom: 0.38095rem; }

.small-padding-2 {
  padding-top: 0.7619rem;
  padding-bottom: 0.7619rem; }

.small-padding-3 {
  padding-top: 1.14286rem;
  padding-bottom: 1.14286rem; }

.small-padding-4 {
  padding-top: 1.52381rem;
  padding-bottom: 1.52381rem; }

.small-padding-5 {
  padding-top: 1.90476rem;
  padding-bottom: 1.90476rem; }

.small-padding-6 {
  padding-top: 2.28571rem;
  padding-bottom: 2.28571rem; }

.small-padding-7 {
  padding-top: 2.66667rem;
  padding-bottom: 2.66667rem; }

.small-padding-8 {
  padding-top: 3.04762rem;
  padding-bottom: 3.04762rem; }

.small-padding-9 {
  padding-top: 3.42857rem;
  padding-bottom: 3.42857rem; }

.small-padding-10 {
  padding-top: 3.80952rem;
  padding-bottom: 3.80952rem; }

.small-padding-11 {
  padding-top: 4.19048rem;
  padding-bottom: 4.19048rem; }

.small-padding-12 {
  padding-top: 4.57143rem;
  padding-bottom: 4.57143rem; }

.small-padding-13 {
  padding-top: 4.95238rem;
  padding-bottom: 4.95238rem; }

.small-padding-14 {
  padding-top: 5.33333rem;
  padding-bottom: 5.33333rem; }

.small-padding-15 {
  padding-top: 5.71429rem;
  padding-bottom: 5.71429rem; }

.small-padding-16 {
  padding-top: 6.09524rem;
  padding-bottom: 6.09524rem; }

.small-padding-17 {
  padding-top: 6.47619rem;
  padding-bottom: 6.47619rem; }

.small-padding-18 {
  padding-top: 6.85714rem;
  padding-bottom: 6.85714rem; }

.small-padding-19 {
  padding-top: 7.2381rem;
  padding-bottom: 7.2381rem; }

.small-padding-20 {
  padding-top: 7.61905rem;
  padding-bottom: 7.61905rem; }

.small-padding-21 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.small-padding-22 {
  padding-top: 8.38095rem;
  padding-bottom: 8.38095rem; }

.small-padding-23 {
  padding-top: 8.7619rem;
  padding-bottom: 8.7619rem; }

.small-padding-24 {
  padding-top: 9.14286rem;
  padding-bottom: 9.14286rem; }

.small-padding-25 {
  padding-top: 9.52381rem;
  padding-bottom: 9.52381rem; }

.small-padding-26 {
  padding-top: 9.90476rem;
  padding-bottom: 9.90476rem; }

.small-padding-27 {
  padding-top: 10.28571rem;
  padding-bottom: 10.28571rem; }

.small-padding-28 {
  padding-top: 10.66667rem;
  padding-bottom: 10.66667rem; }

.small-padding-29 {
  padding-top: 11.04762rem;
  padding-bottom: 11.04762rem; }

.small-padding-30 {
  padding-top: 11.42857rem;
  padding-bottom: 11.42857rem; }

.small-padding-31 {
  padding-top: 11.80952rem;
  padding-bottom: 11.80952rem; }

.small-padding-32 {
  padding-top: 12.19048rem;
  padding-bottom: 12.19048rem; }

.small-padding-33 {
  padding-top: 12.57143rem;
  padding-bottom: 12.57143rem; }

.small-padding-34 {
  padding-top: 12.95238rem;
  padding-bottom: 12.95238rem; }

.small-padding-35 {
  padding-top: 13.33333rem;
  padding-bottom: 13.33333rem; }

.small-padding-36 {
  padding-top: 13.71429rem;
  padding-bottom: 13.71429rem; }

.small-padding-37 {
  padding-top: 14.09524rem;
  padding-bottom: 14.09524rem; }

.small-padding-38 {
  padding-top: 14.47619rem;
  padding-bottom: 14.47619rem; }

.small-padding-39 {
  padding-top: 14.85714rem;
  padding-bottom: 14.85714rem; }

.small-padding-40 {
  padding-top: 15.2381rem;
  padding-bottom: 15.2381rem; }

.small-padding-41 {
  padding-top: 15.61905rem;
  padding-bottom: 15.61905rem; }

.small-padding-42 {
  padding-top: 16rem;
  padding-bottom: 16rem; }

.small-padding-43 {
  padding-top: 16.38095rem;
  padding-bottom: 16.38095rem; }

.small-padding-44 {
  padding-top: 16.7619rem;
  padding-bottom: 16.7619rem; }

.small-padding-45 {
  padding-top: 17.14286rem;
  padding-bottom: 17.14286rem; }

.small-padding-46 {
  padding-top: 17.52381rem;
  padding-bottom: 17.52381rem; }

.small-padding-47 {
  padding-top: 17.90476rem;
  padding-bottom: 17.90476rem; }

.small-padding-48 {
  padding-top: 18.28571rem;
  padding-bottom: 18.28571rem; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-margin-top-0 {
    margin-top: 0; }
  .medium-margin-top-1 {
    margin-top: 0.38095rem; }
  .medium-margin-top-2 {
    margin-top: 0.7619rem; }
  .medium-margin-top-3 {
    margin-top: 1.14286rem; }
  .medium-margin-top-4 {
    margin-top: 1.52381rem; }
  .medium-margin-top-5 {
    margin-top: 1.90476rem; }
  .medium-margin-top-6 {
    margin-top: 2.28571rem; }
  .medium-margin-top-7 {
    margin-top: 2.66667rem; }
  .medium-margin-top-8 {
    margin-top: 3.04762rem; }
  .medium-margin-top-9 {
    margin-top: 3.42857rem; }
  .medium-margin-top-10 {
    margin-top: 3.80952rem; }
  .medium-margin-top-11 {
    margin-top: 4.19048rem; }
  .medium-margin-top-12 {
    margin-top: 4.57143rem; }
  .medium-margin-top-13 {
    margin-top: 4.95238rem; }
  .medium-margin-top-14 {
    margin-top: 5.33333rem; }
  .medium-margin-top-15 {
    margin-top: 5.71429rem; }
  .medium-margin-top-16 {
    margin-top: 6.09524rem; }
  .medium-margin-top-17 {
    margin-top: 6.47619rem; }
  .medium-margin-top-18 {
    margin-top: 6.85714rem; }
  .medium-margin-top-19 {
    margin-top: 7.2381rem; }
  .medium-margin-top-20 {
    margin-top: 7.61905rem; }
  .medium-margin-top-21 {
    margin-top: 8rem; }
  .medium-margin-top-22 {
    margin-top: 8.38095rem; }
  .medium-margin-top-23 {
    margin-top: 8.7619rem; }
  .medium-margin-top-24 {
    margin-top: 9.14286rem; }
  .medium-margin-top-25 {
    margin-top: 9.52381rem; }
  .medium-margin-top-26 {
    margin-top: 9.90476rem; }
  .medium-margin-top-27 {
    margin-top: 10.28571rem; }
  .medium-margin-top-28 {
    margin-top: 10.66667rem; }
  .medium-margin-top-29 {
    margin-top: 11.04762rem; }
  .medium-margin-top-30 {
    margin-top: 11.42857rem; }
  .medium-margin-top-31 {
    margin-top: 11.80952rem; }
  .medium-margin-top-32 {
    margin-top: 12.19048rem; }
  .medium-margin-top-33 {
    margin-top: 12.57143rem; }
  .medium-margin-top-34 {
    margin-top: 12.95238rem; }
  .medium-margin-top-35 {
    margin-top: 13.33333rem; }
  .medium-margin-top-36 {
    margin-top: 13.71429rem; }
  .medium-margin-top-37 {
    margin-top: 14.09524rem; }
  .medium-margin-top-38 {
    margin-top: 14.47619rem; }
  .medium-margin-top-39 {
    margin-top: 14.85714rem; }
  .medium-margin-top-40 {
    margin-top: 15.2381rem; }
  .medium-margin-top-41 {
    margin-top: 15.61905rem; }
  .medium-margin-top-42 {
    margin-top: 16rem; }
  .medium-margin-top-43 {
    margin-top: 16.38095rem; }
  .medium-margin-top-44 {
    margin-top: 16.7619rem; }
  .medium-margin-top-45 {
    margin-top: 17.14286rem; }
  .medium-margin-top-46 {
    margin-top: 17.52381rem; }
  .medium-margin-top-47 {
    margin-top: 17.90476rem; }
  .medium-margin-top-48 {
    margin-top: 18.28571rem; }
  .medium-margin-bottom-0 {
    margin-bottom: 0; }
  .medium-margin-bottom-1 {
    margin-bottom: 0.38095rem; }
  .medium-margin-bottom-2 {
    margin-bottom: 0.7619rem; }
  .medium-margin-bottom-3 {
    margin-bottom: 1.14286rem; }
  .medium-margin-bottom-4 {
    margin-bottom: 1.52381rem; }
  .medium-margin-bottom-5 {
    margin-bottom: 1.90476rem; }
  .medium-margin-bottom-6 {
    margin-bottom: 2.28571rem; }
  .medium-margin-bottom-7 {
    margin-bottom: 2.66667rem; }
  .medium-margin-bottom-8 {
    margin-bottom: 3.04762rem; }
  .medium-margin-bottom-9 {
    margin-bottom: 3.42857rem; }
  .medium-margin-bottom-10 {
    margin-bottom: 3.80952rem; }
  .medium-margin-bottom-11 {
    margin-bottom: 4.19048rem; }
  .medium-margin-bottom-12 {
    margin-bottom: 4.57143rem; }
  .medium-margin-bottom-13 {
    margin-bottom: 4.95238rem; }
  .medium-margin-bottom-14 {
    margin-bottom: 5.33333rem; }
  .medium-margin-bottom-15 {
    margin-bottom: 5.71429rem; }
  .medium-margin-bottom-16 {
    margin-bottom: 6.09524rem; }
  .medium-margin-bottom-17 {
    margin-bottom: 6.47619rem; }
  .medium-margin-bottom-18 {
    margin-bottom: 6.85714rem; }
  .medium-margin-bottom-19 {
    margin-bottom: 7.2381rem; }
  .medium-margin-bottom-20 {
    margin-bottom: 7.61905rem; }
  .medium-margin-bottom-21 {
    margin-bottom: 8rem; }
  .medium-margin-bottom-22 {
    margin-bottom: 8.38095rem; }
  .medium-margin-bottom-23 {
    margin-bottom: 8.7619rem; }
  .medium-margin-bottom-24 {
    margin-bottom: 9.14286rem; }
  .medium-margin-bottom-25 {
    margin-bottom: 9.52381rem; }
  .medium-margin-bottom-26 {
    margin-bottom: 9.90476rem; }
  .medium-margin-bottom-27 {
    margin-bottom: 10.28571rem; }
  .medium-margin-bottom-28 {
    margin-bottom: 10.66667rem; }
  .medium-margin-bottom-29 {
    margin-bottom: 11.04762rem; }
  .medium-margin-bottom-30 {
    margin-bottom: 11.42857rem; }
  .medium-margin-bottom-31 {
    margin-bottom: 11.80952rem; }
  .medium-margin-bottom-32 {
    margin-bottom: 12.19048rem; }
  .medium-margin-bottom-33 {
    margin-bottom: 12.57143rem; }
  .medium-margin-bottom-34 {
    margin-bottom: 12.95238rem; }
  .medium-margin-bottom-35 {
    margin-bottom: 13.33333rem; }
  .medium-margin-bottom-36 {
    margin-bottom: 13.71429rem; }
  .medium-margin-bottom-37 {
    margin-bottom: 14.09524rem; }
  .medium-margin-bottom-38 {
    margin-bottom: 14.47619rem; }
  .medium-margin-bottom-39 {
    margin-bottom: 14.85714rem; }
  .medium-margin-bottom-40 {
    margin-bottom: 15.2381rem; }
  .medium-margin-bottom-41 {
    margin-bottom: 15.61905rem; }
  .medium-margin-bottom-42 {
    margin-bottom: 16rem; }
  .medium-margin-bottom-43 {
    margin-bottom: 16.38095rem; }
  .medium-margin-bottom-44 {
    margin-bottom: 16.7619rem; }
  .medium-margin-bottom-45 {
    margin-bottom: 17.14286rem; }
  .medium-margin-bottom-46 {
    margin-bottom: 17.52381rem; }
  .medium-margin-bottom-47 {
    margin-bottom: 17.90476rem; }
  .medium-margin-bottom-48 {
    margin-bottom: 18.28571rem; }
  .medium-margin-right-0 {
    margin-right: 0; }
  .medium-margin-right-1 {
    margin-right: 0.38095rem; }
  .medium-margin-right-2 {
    margin-right: 0.7619rem; }
  .medium-margin-right-3 {
    margin-right: 1.14286rem; }
  .medium-margin-right-4 {
    margin-right: 1.52381rem; }
  .medium-margin-right-5 {
    margin-right: 1.90476rem; }
  .medium-margin-right-6 {
    margin-right: 2.28571rem; }
  .medium-margin-right-7 {
    margin-right: 2.66667rem; }
  .medium-margin-right-8 {
    margin-right: 3.04762rem; }
  .medium-margin-right-9 {
    margin-right: 3.42857rem; }
  .medium-margin-right-10 {
    margin-right: 3.80952rem; }
  .medium-margin-right-11 {
    margin-right: 4.19048rem; }
  .medium-margin-right-12 {
    margin-right: 4.57143rem; }
  .medium-margin-right-13 {
    margin-right: 4.95238rem; }
  .medium-margin-right-14 {
    margin-right: 5.33333rem; }
  .medium-margin-right-15 {
    margin-right: 5.71429rem; }
  .medium-margin-right-16 {
    margin-right: 6.09524rem; }
  .medium-margin-right-17 {
    margin-right: 6.47619rem; }
  .medium-margin-right-18 {
    margin-right: 6.85714rem; }
  .medium-margin-right-19 {
    margin-right: 7.2381rem; }
  .medium-margin-right-20 {
    margin-right: 7.61905rem; }
  .medium-margin-right-21 {
    margin-right: 8rem; }
  .medium-margin-right-22 {
    margin-right: 8.38095rem; }
  .medium-margin-right-23 {
    margin-right: 8.7619rem; }
  .medium-margin-right-24 {
    margin-right: 9.14286rem; }
  .medium-margin-right-25 {
    margin-right: 9.52381rem; }
  .medium-margin-right-26 {
    margin-right: 9.90476rem; }
  .medium-margin-right-27 {
    margin-right: 10.28571rem; }
  .medium-margin-right-28 {
    margin-right: 10.66667rem; }
  .medium-margin-right-29 {
    margin-right: 11.04762rem; }
  .medium-margin-right-30 {
    margin-right: 11.42857rem; }
  .medium-margin-right-31 {
    margin-right: 11.80952rem; }
  .medium-margin-right-32 {
    margin-right: 12.19048rem; }
  .medium-margin-right-33 {
    margin-right: 12.57143rem; }
  .medium-margin-right-34 {
    margin-right: 12.95238rem; }
  .medium-margin-right-35 {
    margin-right: 13.33333rem; }
  .medium-margin-right-36 {
    margin-right: 13.71429rem; }
  .medium-margin-right-37 {
    margin-right: 14.09524rem; }
  .medium-margin-right-38 {
    margin-right: 14.47619rem; }
  .medium-margin-right-39 {
    margin-right: 14.85714rem; }
  .medium-margin-right-40 {
    margin-right: 15.2381rem; }
  .medium-margin-right-41 {
    margin-right: 15.61905rem; }
  .medium-margin-right-42 {
    margin-right: 16rem; }
  .medium-margin-right-43 {
    margin-right: 16.38095rem; }
  .medium-margin-right-44 {
    margin-right: 16.7619rem; }
  .medium-margin-right-45 {
    margin-right: 17.14286rem; }
  .medium-margin-right-46 {
    margin-right: 17.52381rem; }
  .medium-margin-right-47 {
    margin-right: 17.90476rem; }
  .medium-margin-right-48 {
    margin-right: 18.28571rem; }
  .medium-margin-left-0 {
    margin-left: 0; }
  .medium-margin-left-1 {
    margin-left: 0.38095rem; }
  .medium-margin-left-2 {
    margin-left: 0.7619rem; }
  .medium-margin-left-3 {
    margin-left: 1.14286rem; }
  .medium-margin-left-4 {
    margin-left: 1.52381rem; }
  .medium-margin-left-5 {
    margin-left: 1.90476rem; }
  .medium-margin-left-6 {
    margin-left: 2.28571rem; }
  .medium-margin-left-7 {
    margin-left: 2.66667rem; }
  .medium-margin-left-8 {
    margin-left: 3.04762rem; }
  .medium-margin-left-9 {
    margin-left: 3.42857rem; }
  .medium-margin-left-10 {
    margin-left: 3.80952rem; }
  .medium-margin-left-11 {
    margin-left: 4.19048rem; }
  .medium-margin-left-12 {
    margin-left: 4.57143rem; }
  .medium-margin-left-13 {
    margin-left: 4.95238rem; }
  .medium-margin-left-14 {
    margin-left: 5.33333rem; }
  .medium-margin-left-15 {
    margin-left: 5.71429rem; }
  .medium-margin-left-16 {
    margin-left: 6.09524rem; }
  .medium-margin-left-17 {
    margin-left: 6.47619rem; }
  .medium-margin-left-18 {
    margin-left: 6.85714rem; }
  .medium-margin-left-19 {
    margin-left: 7.2381rem; }
  .medium-margin-left-20 {
    margin-left: 7.61905rem; }
  .medium-margin-left-21 {
    margin-left: 8rem; }
  .medium-margin-left-22 {
    margin-left: 8.38095rem; }
  .medium-margin-left-23 {
    margin-left: 8.7619rem; }
  .medium-margin-left-24 {
    margin-left: 9.14286rem; }
  .medium-margin-left-25 {
    margin-left: 9.52381rem; }
  .medium-margin-left-26 {
    margin-left: 9.90476rem; }
  .medium-margin-left-27 {
    margin-left: 10.28571rem; }
  .medium-margin-left-28 {
    margin-left: 10.66667rem; }
  .medium-margin-left-29 {
    margin-left: 11.04762rem; }
  .medium-margin-left-30 {
    margin-left: 11.42857rem; }
  .medium-margin-left-31 {
    margin-left: 11.80952rem; }
  .medium-margin-left-32 {
    margin-left: 12.19048rem; }
  .medium-margin-left-33 {
    margin-left: 12.57143rem; }
  .medium-margin-left-34 {
    margin-left: 12.95238rem; }
  .medium-margin-left-35 {
    margin-left: 13.33333rem; }
  .medium-margin-left-36 {
    margin-left: 13.71429rem; }
  .medium-margin-left-37 {
    margin-left: 14.09524rem; }
  .medium-margin-left-38 {
    margin-left: 14.47619rem; }
  .medium-margin-left-39 {
    margin-left: 14.85714rem; }
  .medium-margin-left-40 {
    margin-left: 15.2381rem; }
  .medium-margin-left-41 {
    margin-left: 15.61905rem; }
  .medium-margin-left-42 {
    margin-left: 16rem; }
  .medium-margin-left-43 {
    margin-left: 16.38095rem; }
  .medium-margin-left-44 {
    margin-left: 16.7619rem; }
  .medium-margin-left-45 {
    margin-left: 17.14286rem; }
  .medium-margin-left-46 {
    margin-left: 17.52381rem; }
  .medium-margin-left-47 {
    margin-left: 17.90476rem; }
  .medium-margin-left-48 {
    margin-left: 18.28571rem; }
  .medium-margin-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .medium-margin-1 {
    margin-top: 0.38095rem;
    margin-bottom: 0.38095rem; }
  .medium-margin-2 {
    margin-top: 0.7619rem;
    margin-bottom: 0.7619rem; }
  .medium-margin-3 {
    margin-top: 1.14286rem;
    margin-bottom: 1.14286rem; }
  .medium-margin-4 {
    margin-top: 1.52381rem;
    margin-bottom: 1.52381rem; }
  .medium-margin-5 {
    margin-top: 1.90476rem;
    margin-bottom: 1.90476rem; }
  .medium-margin-6 {
    margin-top: 2.28571rem;
    margin-bottom: 2.28571rem; }
  .medium-margin-7 {
    margin-top: 2.66667rem;
    margin-bottom: 2.66667rem; }
  .medium-margin-8 {
    margin-top: 3.04762rem;
    margin-bottom: 3.04762rem; }
  .medium-margin-9 {
    margin-top: 3.42857rem;
    margin-bottom: 3.42857rem; }
  .medium-margin-10 {
    margin-top: 3.80952rem;
    margin-bottom: 3.80952rem; }
  .medium-margin-11 {
    margin-top: 4.19048rem;
    margin-bottom: 4.19048rem; }
  .medium-margin-12 {
    margin-top: 4.57143rem;
    margin-bottom: 4.57143rem; }
  .medium-margin-13 {
    margin-top: 4.95238rem;
    margin-bottom: 4.95238rem; }
  .medium-margin-14 {
    margin-top: 5.33333rem;
    margin-bottom: 5.33333rem; }
  .medium-margin-15 {
    margin-top: 5.71429rem;
    margin-bottom: 5.71429rem; }
  .medium-margin-16 {
    margin-top: 6.09524rem;
    margin-bottom: 6.09524rem; }
  .medium-margin-17 {
    margin-top: 6.47619rem;
    margin-bottom: 6.47619rem; }
  .medium-margin-18 {
    margin-top: 6.85714rem;
    margin-bottom: 6.85714rem; }
  .medium-margin-19 {
    margin-top: 7.2381rem;
    margin-bottom: 7.2381rem; }
  .medium-margin-20 {
    margin-top: 7.61905rem;
    margin-bottom: 7.61905rem; }
  .medium-margin-21 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .medium-margin-22 {
    margin-top: 8.38095rem;
    margin-bottom: 8.38095rem; }
  .medium-margin-23 {
    margin-top: 8.7619rem;
    margin-bottom: 8.7619rem; }
  .medium-margin-24 {
    margin-top: 9.14286rem;
    margin-bottom: 9.14286rem; }
  .medium-margin-25 {
    margin-top: 9.52381rem;
    margin-bottom: 9.52381rem; }
  .medium-margin-26 {
    margin-top: 9.90476rem;
    margin-bottom: 9.90476rem; }
  .medium-margin-27 {
    margin-top: 10.28571rem;
    margin-bottom: 10.28571rem; }
  .medium-margin-28 {
    margin-top: 10.66667rem;
    margin-bottom: 10.66667rem; }
  .medium-margin-29 {
    margin-top: 11.04762rem;
    margin-bottom: 11.04762rem; }
  .medium-margin-30 {
    margin-top: 11.42857rem;
    margin-bottom: 11.42857rem; }
  .medium-margin-31 {
    margin-top: 11.80952rem;
    margin-bottom: 11.80952rem; }
  .medium-margin-32 {
    margin-top: 12.19048rem;
    margin-bottom: 12.19048rem; }
  .medium-margin-33 {
    margin-top: 12.57143rem;
    margin-bottom: 12.57143rem; }
  .medium-margin-34 {
    margin-top: 12.95238rem;
    margin-bottom: 12.95238rem; }
  .medium-margin-35 {
    margin-top: 13.33333rem;
    margin-bottom: 13.33333rem; }
  .medium-margin-36 {
    margin-top: 13.71429rem;
    margin-bottom: 13.71429rem; }
  .medium-margin-37 {
    margin-top: 14.09524rem;
    margin-bottom: 14.09524rem; }
  .medium-margin-38 {
    margin-top: 14.47619rem;
    margin-bottom: 14.47619rem; }
  .medium-margin-39 {
    margin-top: 14.85714rem;
    margin-bottom: 14.85714rem; }
  .medium-margin-40 {
    margin-top: 15.2381rem;
    margin-bottom: 15.2381rem; }
  .medium-margin-41 {
    margin-top: 15.61905rem;
    margin-bottom: 15.61905rem; }
  .medium-margin-42 {
    margin-top: 16rem;
    margin-bottom: 16rem; }
  .medium-margin-43 {
    margin-top: 16.38095rem;
    margin-bottom: 16.38095rem; }
  .medium-margin-44 {
    margin-top: 16.7619rem;
    margin-bottom: 16.7619rem; }
  .medium-margin-45 {
    margin-top: 17.14286rem;
    margin-bottom: 17.14286rem; }
  .medium-margin-46 {
    margin-top: 17.52381rem;
    margin-bottom: 17.52381rem; }
  .medium-margin-47 {
    margin-top: 17.90476rem;
    margin-bottom: 17.90476rem; }
  .medium-margin-48 {
    margin-top: 18.28571rem;
    margin-bottom: 18.28571rem; }
  .medium-padding-top-0 {
    padding-top: 0; }
  .medium-padding-top-1 {
    padding-top: 0.38095rem; }
  .medium-padding-top-2 {
    padding-top: 0.7619rem; }
  .medium-padding-top-3 {
    padding-top: 1.14286rem; }
  .medium-padding-top-4 {
    padding-top: 1.52381rem; }
  .medium-padding-top-5 {
    padding-top: 1.90476rem; }
  .medium-padding-top-6 {
    padding-top: 2.28571rem; }
  .medium-padding-top-7 {
    padding-top: 2.66667rem; }
  .medium-padding-top-8 {
    padding-top: 3.04762rem; }
  .medium-padding-top-9 {
    padding-top: 3.42857rem; }
  .medium-padding-top-10 {
    padding-top: 3.80952rem; }
  .medium-padding-top-11 {
    padding-top: 4.19048rem; }
  .medium-padding-top-12 {
    padding-top: 4.57143rem; }
  .medium-padding-top-13 {
    padding-top: 4.95238rem; }
  .medium-padding-top-14 {
    padding-top: 5.33333rem; }
  .medium-padding-top-15 {
    padding-top: 5.71429rem; }
  .medium-padding-top-16 {
    padding-top: 6.09524rem; }
  .medium-padding-top-17 {
    padding-top: 6.47619rem; }
  .medium-padding-top-18 {
    padding-top: 6.85714rem; }
  .medium-padding-top-19 {
    padding-top: 7.2381rem; }
  .medium-padding-top-20 {
    padding-top: 7.61905rem; }
  .medium-padding-top-21 {
    padding-top: 8rem; }
  .medium-padding-top-22 {
    padding-top: 8.38095rem; }
  .medium-padding-top-23 {
    padding-top: 8.7619rem; }
  .medium-padding-top-24 {
    padding-top: 9.14286rem; }
  .medium-padding-top-25 {
    padding-top: 9.52381rem; }
  .medium-padding-top-26 {
    padding-top: 9.90476rem; }
  .medium-padding-top-27 {
    padding-top: 10.28571rem; }
  .medium-padding-top-28 {
    padding-top: 10.66667rem; }
  .medium-padding-top-29 {
    padding-top: 11.04762rem; }
  .medium-padding-top-30 {
    padding-top: 11.42857rem; }
  .medium-padding-top-31 {
    padding-top: 11.80952rem; }
  .medium-padding-top-32 {
    padding-top: 12.19048rem; }
  .medium-padding-top-33 {
    padding-top: 12.57143rem; }
  .medium-padding-top-34 {
    padding-top: 12.95238rem; }
  .medium-padding-top-35 {
    padding-top: 13.33333rem; }
  .medium-padding-top-36 {
    padding-top: 13.71429rem; }
  .medium-padding-top-37 {
    padding-top: 14.09524rem; }
  .medium-padding-top-38 {
    padding-top: 14.47619rem; }
  .medium-padding-top-39 {
    padding-top: 14.85714rem; }
  .medium-padding-top-40 {
    padding-top: 15.2381rem; }
  .medium-padding-top-41 {
    padding-top: 15.61905rem; }
  .medium-padding-top-42 {
    padding-top: 16rem; }
  .medium-padding-top-43 {
    padding-top: 16.38095rem; }
  .medium-padding-top-44 {
    padding-top: 16.7619rem; }
  .medium-padding-top-45 {
    padding-top: 17.14286rem; }
  .medium-padding-top-46 {
    padding-top: 17.52381rem; }
  .medium-padding-top-47 {
    padding-top: 17.90476rem; }
  .medium-padding-top-48 {
    padding-top: 18.28571rem; }
  .medium-padding-bottom-0 {
    padding-bottom: 0; }
  .medium-padding-bottom-1 {
    padding-bottom: 0.38095rem; }
  .medium-padding-bottom-2 {
    padding-bottom: 0.7619rem; }
  .medium-padding-bottom-3 {
    padding-bottom: 1.14286rem; }
  .medium-padding-bottom-4 {
    padding-bottom: 1.52381rem; }
  .medium-padding-bottom-5 {
    padding-bottom: 1.90476rem; }
  .medium-padding-bottom-6 {
    padding-bottom: 2.28571rem; }
  .medium-padding-bottom-7 {
    padding-bottom: 2.66667rem; }
  .medium-padding-bottom-8 {
    padding-bottom: 3.04762rem; }
  .medium-padding-bottom-9 {
    padding-bottom: 3.42857rem; }
  .medium-padding-bottom-10 {
    padding-bottom: 3.80952rem; }
  .medium-padding-bottom-11 {
    padding-bottom: 4.19048rem; }
  .medium-padding-bottom-12 {
    padding-bottom: 4.57143rem; }
  .medium-padding-bottom-13 {
    padding-bottom: 4.95238rem; }
  .medium-padding-bottom-14 {
    padding-bottom: 5.33333rem; }
  .medium-padding-bottom-15 {
    padding-bottom: 5.71429rem; }
  .medium-padding-bottom-16 {
    padding-bottom: 6.09524rem; }
  .medium-padding-bottom-17 {
    padding-bottom: 6.47619rem; }
  .medium-padding-bottom-18 {
    padding-bottom: 6.85714rem; }
  .medium-padding-bottom-19 {
    padding-bottom: 7.2381rem; }
  .medium-padding-bottom-20 {
    padding-bottom: 7.61905rem; }
  .medium-padding-bottom-21 {
    padding-bottom: 8rem; }
  .medium-padding-bottom-22 {
    padding-bottom: 8.38095rem; }
  .medium-padding-bottom-23 {
    padding-bottom: 8.7619rem; }
  .medium-padding-bottom-24 {
    padding-bottom: 9.14286rem; }
  .medium-padding-bottom-25 {
    padding-bottom: 9.52381rem; }
  .medium-padding-bottom-26 {
    padding-bottom: 9.90476rem; }
  .medium-padding-bottom-27 {
    padding-bottom: 10.28571rem; }
  .medium-padding-bottom-28 {
    padding-bottom: 10.66667rem; }
  .medium-padding-bottom-29 {
    padding-bottom: 11.04762rem; }
  .medium-padding-bottom-30 {
    padding-bottom: 11.42857rem; }
  .medium-padding-bottom-31 {
    padding-bottom: 11.80952rem; }
  .medium-padding-bottom-32 {
    padding-bottom: 12.19048rem; }
  .medium-padding-bottom-33 {
    padding-bottom: 12.57143rem; }
  .medium-padding-bottom-34 {
    padding-bottom: 12.95238rem; }
  .medium-padding-bottom-35 {
    padding-bottom: 13.33333rem; }
  .medium-padding-bottom-36 {
    padding-bottom: 13.71429rem; }
  .medium-padding-bottom-37 {
    padding-bottom: 14.09524rem; }
  .medium-padding-bottom-38 {
    padding-bottom: 14.47619rem; }
  .medium-padding-bottom-39 {
    padding-bottom: 14.85714rem; }
  .medium-padding-bottom-40 {
    padding-bottom: 15.2381rem; }
  .medium-padding-bottom-41 {
    padding-bottom: 15.61905rem; }
  .medium-padding-bottom-42 {
    padding-bottom: 16rem; }
  .medium-padding-bottom-43 {
    padding-bottom: 16.38095rem; }
  .medium-padding-bottom-44 {
    padding-bottom: 16.7619rem; }
  .medium-padding-bottom-45 {
    padding-bottom: 17.14286rem; }
  .medium-padding-bottom-46 {
    padding-bottom: 17.52381rem; }
  .medium-padding-bottom-47 {
    padding-bottom: 17.90476rem; }
  .medium-padding-bottom-48 {
    padding-bottom: 18.28571rem; }
  .medium-padding-right-0 {
    padding-right: 0; }
  .medium-padding-right-1 {
    padding-right: 0.38095rem; }
  .medium-padding-right-2 {
    padding-right: 0.7619rem; }
  .medium-padding-right-3 {
    padding-right: 1.14286rem; }
  .medium-padding-right-4 {
    padding-right: 1.52381rem; }
  .medium-padding-right-5 {
    padding-right: 1.90476rem; }
  .medium-padding-right-6 {
    padding-right: 2.28571rem; }
  .medium-padding-right-7 {
    padding-right: 2.66667rem; }
  .medium-padding-right-8 {
    padding-right: 3.04762rem; }
  .medium-padding-right-9 {
    padding-right: 3.42857rem; }
  .medium-padding-right-10 {
    padding-right: 3.80952rem; }
  .medium-padding-right-11 {
    padding-right: 4.19048rem; }
  .medium-padding-right-12 {
    padding-right: 4.57143rem; }
  .medium-padding-right-13 {
    padding-right: 4.95238rem; }
  .medium-padding-right-14 {
    padding-right: 5.33333rem; }
  .medium-padding-right-15 {
    padding-right: 5.71429rem; }
  .medium-padding-right-16 {
    padding-right: 6.09524rem; }
  .medium-padding-right-17 {
    padding-right: 6.47619rem; }
  .medium-padding-right-18 {
    padding-right: 6.85714rem; }
  .medium-padding-right-19 {
    padding-right: 7.2381rem; }
  .medium-padding-right-20 {
    padding-right: 7.61905rem; }
  .medium-padding-right-21 {
    padding-right: 8rem; }
  .medium-padding-right-22 {
    padding-right: 8.38095rem; }
  .medium-padding-right-23 {
    padding-right: 8.7619rem; }
  .medium-padding-right-24 {
    padding-right: 9.14286rem; }
  .medium-padding-right-25 {
    padding-right: 9.52381rem; }
  .medium-padding-right-26 {
    padding-right: 9.90476rem; }
  .medium-padding-right-27 {
    padding-right: 10.28571rem; }
  .medium-padding-right-28 {
    padding-right: 10.66667rem; }
  .medium-padding-right-29 {
    padding-right: 11.04762rem; }
  .medium-padding-right-30 {
    padding-right: 11.42857rem; }
  .medium-padding-right-31 {
    padding-right: 11.80952rem; }
  .medium-padding-right-32 {
    padding-right: 12.19048rem; }
  .medium-padding-right-33 {
    padding-right: 12.57143rem; }
  .medium-padding-right-34 {
    padding-right: 12.95238rem; }
  .medium-padding-right-35 {
    padding-right: 13.33333rem; }
  .medium-padding-right-36 {
    padding-right: 13.71429rem; }
  .medium-padding-right-37 {
    padding-right: 14.09524rem; }
  .medium-padding-right-38 {
    padding-right: 14.47619rem; }
  .medium-padding-right-39 {
    padding-right: 14.85714rem; }
  .medium-padding-right-40 {
    padding-right: 15.2381rem; }
  .medium-padding-right-41 {
    padding-right: 15.61905rem; }
  .medium-padding-right-42 {
    padding-right: 16rem; }
  .medium-padding-right-43 {
    padding-right: 16.38095rem; }
  .medium-padding-right-44 {
    padding-right: 16.7619rem; }
  .medium-padding-right-45 {
    padding-right: 17.14286rem; }
  .medium-padding-right-46 {
    padding-right: 17.52381rem; }
  .medium-padding-right-47 {
    padding-right: 17.90476rem; }
  .medium-padding-right-48 {
    padding-right: 18.28571rem; }
  .medium-padding-left-0 {
    padding-left: 0; }
  .medium-padding-left-1 {
    padding-left: 0.38095rem; }
  .medium-padding-left-2 {
    padding-left: 0.7619rem; }
  .medium-padding-left-3 {
    padding-left: 1.14286rem; }
  .medium-padding-left-4 {
    padding-left: 1.52381rem; }
  .medium-padding-left-5 {
    padding-left: 1.90476rem; }
  .medium-padding-left-6 {
    padding-left: 2.28571rem; }
  .medium-padding-left-7 {
    padding-left: 2.66667rem; }
  .medium-padding-left-8 {
    padding-left: 3.04762rem; }
  .medium-padding-left-9 {
    padding-left: 3.42857rem; }
  .medium-padding-left-10 {
    padding-left: 3.80952rem; }
  .medium-padding-left-11 {
    padding-left: 4.19048rem; }
  .medium-padding-left-12 {
    padding-left: 4.57143rem; }
  .medium-padding-left-13 {
    padding-left: 4.95238rem; }
  .medium-padding-left-14 {
    padding-left: 5.33333rem; }
  .medium-padding-left-15 {
    padding-left: 5.71429rem; }
  .medium-padding-left-16 {
    padding-left: 6.09524rem; }
  .medium-padding-left-17 {
    padding-left: 6.47619rem; }
  .medium-padding-left-18 {
    padding-left: 6.85714rem; }
  .medium-padding-left-19 {
    padding-left: 7.2381rem; }
  .medium-padding-left-20 {
    padding-left: 7.61905rem; }
  .medium-padding-left-21 {
    padding-left: 8rem; }
  .medium-padding-left-22 {
    padding-left: 8.38095rem; }
  .medium-padding-left-23 {
    padding-left: 8.7619rem; }
  .medium-padding-left-24 {
    padding-left: 9.14286rem; }
  .medium-padding-left-25 {
    padding-left: 9.52381rem; }
  .medium-padding-left-26 {
    padding-left: 9.90476rem; }
  .medium-padding-left-27 {
    padding-left: 10.28571rem; }
  .medium-padding-left-28 {
    padding-left: 10.66667rem; }
  .medium-padding-left-29 {
    padding-left: 11.04762rem; }
  .medium-padding-left-30 {
    padding-left: 11.42857rem; }
  .medium-padding-left-31 {
    padding-left: 11.80952rem; }
  .medium-padding-left-32 {
    padding-left: 12.19048rem; }
  .medium-padding-left-33 {
    padding-left: 12.57143rem; }
  .medium-padding-left-34 {
    padding-left: 12.95238rem; }
  .medium-padding-left-35 {
    padding-left: 13.33333rem; }
  .medium-padding-left-36 {
    padding-left: 13.71429rem; }
  .medium-padding-left-37 {
    padding-left: 14.09524rem; }
  .medium-padding-left-38 {
    padding-left: 14.47619rem; }
  .medium-padding-left-39 {
    padding-left: 14.85714rem; }
  .medium-padding-left-40 {
    padding-left: 15.2381rem; }
  .medium-padding-left-41 {
    padding-left: 15.61905rem; }
  .medium-padding-left-42 {
    padding-left: 16rem; }
  .medium-padding-left-43 {
    padding-left: 16.38095rem; }
  .medium-padding-left-44 {
    padding-left: 16.7619rem; }
  .medium-padding-left-45 {
    padding-left: 17.14286rem; }
  .medium-padding-left-46 {
    padding-left: 17.52381rem; }
  .medium-padding-left-47 {
    padding-left: 17.90476rem; }
  .medium-padding-left-48 {
    padding-left: 18.28571rem; }
  .medium-padding-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .medium-padding-1 {
    padding-top: 0.38095rem;
    padding-bottom: 0.38095rem; }
  .medium-padding-2 {
    padding-top: 0.7619rem;
    padding-bottom: 0.7619rem; }
  .medium-padding-3 {
    padding-top: 1.14286rem;
    padding-bottom: 1.14286rem; }
  .medium-padding-4 {
    padding-top: 1.52381rem;
    padding-bottom: 1.52381rem; }
  .medium-padding-5 {
    padding-top: 1.90476rem;
    padding-bottom: 1.90476rem; }
  .medium-padding-6 {
    padding-top: 2.28571rem;
    padding-bottom: 2.28571rem; }
  .medium-padding-7 {
    padding-top: 2.66667rem;
    padding-bottom: 2.66667rem; }
  .medium-padding-8 {
    padding-top: 3.04762rem;
    padding-bottom: 3.04762rem; }
  .medium-padding-9 {
    padding-top: 3.42857rem;
    padding-bottom: 3.42857rem; }
  .medium-padding-10 {
    padding-top: 3.80952rem;
    padding-bottom: 3.80952rem; }
  .medium-padding-11 {
    padding-top: 4.19048rem;
    padding-bottom: 4.19048rem; }
  .medium-padding-12 {
    padding-top: 4.57143rem;
    padding-bottom: 4.57143rem; }
  .medium-padding-13 {
    padding-top: 4.95238rem;
    padding-bottom: 4.95238rem; }
  .medium-padding-14 {
    padding-top: 5.33333rem;
    padding-bottom: 5.33333rem; }
  .medium-padding-15 {
    padding-top: 5.71429rem;
    padding-bottom: 5.71429rem; }
  .medium-padding-16 {
    padding-top: 6.09524rem;
    padding-bottom: 6.09524rem; }
  .medium-padding-17 {
    padding-top: 6.47619rem;
    padding-bottom: 6.47619rem; }
  .medium-padding-18 {
    padding-top: 6.85714rem;
    padding-bottom: 6.85714rem; }
  .medium-padding-19 {
    padding-top: 7.2381rem;
    padding-bottom: 7.2381rem; }
  .medium-padding-20 {
    padding-top: 7.61905rem;
    padding-bottom: 7.61905rem; }
  .medium-padding-21 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .medium-padding-22 {
    padding-top: 8.38095rem;
    padding-bottom: 8.38095rem; }
  .medium-padding-23 {
    padding-top: 8.7619rem;
    padding-bottom: 8.7619rem; }
  .medium-padding-24 {
    padding-top: 9.14286rem;
    padding-bottom: 9.14286rem; }
  .medium-padding-25 {
    padding-top: 9.52381rem;
    padding-bottom: 9.52381rem; }
  .medium-padding-26 {
    padding-top: 9.90476rem;
    padding-bottom: 9.90476rem; }
  .medium-padding-27 {
    padding-top: 10.28571rem;
    padding-bottom: 10.28571rem; }
  .medium-padding-28 {
    padding-top: 10.66667rem;
    padding-bottom: 10.66667rem; }
  .medium-padding-29 {
    padding-top: 11.04762rem;
    padding-bottom: 11.04762rem; }
  .medium-padding-30 {
    padding-top: 11.42857rem;
    padding-bottom: 11.42857rem; }
  .medium-padding-31 {
    padding-top: 11.80952rem;
    padding-bottom: 11.80952rem; }
  .medium-padding-32 {
    padding-top: 12.19048rem;
    padding-bottom: 12.19048rem; }
  .medium-padding-33 {
    padding-top: 12.57143rem;
    padding-bottom: 12.57143rem; }
  .medium-padding-34 {
    padding-top: 12.95238rem;
    padding-bottom: 12.95238rem; }
  .medium-padding-35 {
    padding-top: 13.33333rem;
    padding-bottom: 13.33333rem; }
  .medium-padding-36 {
    padding-top: 13.71429rem;
    padding-bottom: 13.71429rem; }
  .medium-padding-37 {
    padding-top: 14.09524rem;
    padding-bottom: 14.09524rem; }
  .medium-padding-38 {
    padding-top: 14.47619rem;
    padding-bottom: 14.47619rem; }
  .medium-padding-39 {
    padding-top: 14.85714rem;
    padding-bottom: 14.85714rem; }
  .medium-padding-40 {
    padding-top: 15.2381rem;
    padding-bottom: 15.2381rem; }
  .medium-padding-41 {
    padding-top: 15.61905rem;
    padding-bottom: 15.61905rem; }
  .medium-padding-42 {
    padding-top: 16rem;
    padding-bottom: 16rem; }
  .medium-padding-43 {
    padding-top: 16.38095rem;
    padding-bottom: 16.38095rem; }
  .medium-padding-44 {
    padding-top: 16.7619rem;
    padding-bottom: 16.7619rem; }
  .medium-padding-45 {
    padding-top: 17.14286rem;
    padding-bottom: 17.14286rem; }
  .medium-padding-46 {
    padding-top: 17.52381rem;
    padding-bottom: 17.52381rem; }
  .medium-padding-47 {
    padding-top: 17.90476rem;
    padding-bottom: 17.90476rem; }
  .medium-padding-48 {
    padding-top: 18.28571rem;
    padding-bottom: 18.28571rem; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-margin-top-0 {
    margin-top: 0; }
  .large-margin-top-1 {
    margin-top: 0.38095rem; }
  .large-margin-top-2 {
    margin-top: 0.7619rem; }
  .large-margin-top-3 {
    margin-top: 1.14286rem; }
  .large-margin-top-4 {
    margin-top: 1.52381rem; }
  .large-margin-top-5 {
    margin-top: 1.90476rem; }
  .large-margin-top-6 {
    margin-top: 2.28571rem; }
  .large-margin-top-7 {
    margin-top: 2.66667rem; }
  .large-margin-top-8 {
    margin-top: 3.04762rem; }
  .large-margin-top-9 {
    margin-top: 3.42857rem; }
  .large-margin-top-10 {
    margin-top: 3.80952rem; }
  .large-margin-top-11 {
    margin-top: 4.19048rem; }
  .large-margin-top-12 {
    margin-top: 4.57143rem; }
  .large-margin-top-13 {
    margin-top: 4.95238rem; }
  .large-margin-top-14 {
    margin-top: 5.33333rem; }
  .large-margin-top-15 {
    margin-top: 5.71429rem; }
  .large-margin-top-16 {
    margin-top: 6.09524rem; }
  .large-margin-top-17 {
    margin-top: 6.47619rem; }
  .large-margin-top-18 {
    margin-top: 6.85714rem; }
  .large-margin-top-19 {
    margin-top: 7.2381rem; }
  .large-margin-top-20 {
    margin-top: 7.61905rem; }
  .large-margin-top-21 {
    margin-top: 8rem; }
  .large-margin-top-22 {
    margin-top: 8.38095rem; }
  .large-margin-top-23 {
    margin-top: 8.7619rem; }
  .large-margin-top-24 {
    margin-top: 9.14286rem; }
  .large-margin-top-25 {
    margin-top: 9.52381rem; }
  .large-margin-top-26 {
    margin-top: 9.90476rem; }
  .large-margin-top-27 {
    margin-top: 10.28571rem; }
  .large-margin-top-28 {
    margin-top: 10.66667rem; }
  .large-margin-top-29 {
    margin-top: 11.04762rem; }
  .large-margin-top-30 {
    margin-top: 11.42857rem; }
  .large-margin-top-31 {
    margin-top: 11.80952rem; }
  .large-margin-top-32 {
    margin-top: 12.19048rem; }
  .large-margin-top-33 {
    margin-top: 12.57143rem; }
  .large-margin-top-34 {
    margin-top: 12.95238rem; }
  .large-margin-top-35 {
    margin-top: 13.33333rem; }
  .large-margin-top-36 {
    margin-top: 13.71429rem; }
  .large-margin-top-37 {
    margin-top: 14.09524rem; }
  .large-margin-top-38 {
    margin-top: 14.47619rem; }
  .large-margin-top-39 {
    margin-top: 14.85714rem; }
  .large-margin-top-40 {
    margin-top: 15.2381rem; }
  .large-margin-top-41 {
    margin-top: 15.61905rem; }
  .large-margin-top-42 {
    margin-top: 16rem; }
  .large-margin-top-43 {
    margin-top: 16.38095rem; }
  .large-margin-top-44 {
    margin-top: 16.7619rem; }
  .large-margin-top-45 {
    margin-top: 17.14286rem; }
  .large-margin-top-46 {
    margin-top: 17.52381rem; }
  .large-margin-top-47 {
    margin-top: 17.90476rem; }
  .large-margin-top-48 {
    margin-top: 18.28571rem; }
  .large-margin-bottom-0 {
    margin-bottom: 0; }
  .large-margin-bottom-1 {
    margin-bottom: 0.38095rem; }
  .large-margin-bottom-2 {
    margin-bottom: 0.7619rem; }
  .large-margin-bottom-3 {
    margin-bottom: 1.14286rem; }
  .large-margin-bottom-4 {
    margin-bottom: 1.52381rem; }
  .large-margin-bottom-5 {
    margin-bottom: 1.90476rem; }
  .large-margin-bottom-6 {
    margin-bottom: 2.28571rem; }
  .large-margin-bottom-7 {
    margin-bottom: 2.66667rem; }
  .large-margin-bottom-8 {
    margin-bottom: 3.04762rem; }
  .large-margin-bottom-9 {
    margin-bottom: 3.42857rem; }
  .large-margin-bottom-10 {
    margin-bottom: 3.80952rem; }
  .large-margin-bottom-11 {
    margin-bottom: 4.19048rem; }
  .large-margin-bottom-12 {
    margin-bottom: 4.57143rem; }
  .large-margin-bottom-13 {
    margin-bottom: 4.95238rem; }
  .large-margin-bottom-14 {
    margin-bottom: 5.33333rem; }
  .large-margin-bottom-15 {
    margin-bottom: 5.71429rem; }
  .large-margin-bottom-16 {
    margin-bottom: 6.09524rem; }
  .large-margin-bottom-17 {
    margin-bottom: 6.47619rem; }
  .large-margin-bottom-18 {
    margin-bottom: 6.85714rem; }
  .large-margin-bottom-19 {
    margin-bottom: 7.2381rem; }
  .large-margin-bottom-20 {
    margin-bottom: 7.61905rem; }
  .large-margin-bottom-21 {
    margin-bottom: 8rem; }
  .large-margin-bottom-22 {
    margin-bottom: 8.38095rem; }
  .large-margin-bottom-23 {
    margin-bottom: 8.7619rem; }
  .large-margin-bottom-24 {
    margin-bottom: 9.14286rem; }
  .large-margin-bottom-25 {
    margin-bottom: 9.52381rem; }
  .large-margin-bottom-26 {
    margin-bottom: 9.90476rem; }
  .large-margin-bottom-27 {
    margin-bottom: 10.28571rem; }
  .large-margin-bottom-28 {
    margin-bottom: 10.66667rem; }
  .large-margin-bottom-29 {
    margin-bottom: 11.04762rem; }
  .large-margin-bottom-30 {
    margin-bottom: 11.42857rem; }
  .large-margin-bottom-31 {
    margin-bottom: 11.80952rem; }
  .large-margin-bottom-32 {
    margin-bottom: 12.19048rem; }
  .large-margin-bottom-33 {
    margin-bottom: 12.57143rem; }
  .large-margin-bottom-34 {
    margin-bottom: 12.95238rem; }
  .large-margin-bottom-35 {
    margin-bottom: 13.33333rem; }
  .large-margin-bottom-36 {
    margin-bottom: 13.71429rem; }
  .large-margin-bottom-37 {
    margin-bottom: 14.09524rem; }
  .large-margin-bottom-38 {
    margin-bottom: 14.47619rem; }
  .large-margin-bottom-39 {
    margin-bottom: 14.85714rem; }
  .large-margin-bottom-40 {
    margin-bottom: 15.2381rem; }
  .large-margin-bottom-41 {
    margin-bottom: 15.61905rem; }
  .large-margin-bottom-42 {
    margin-bottom: 16rem; }
  .large-margin-bottom-43 {
    margin-bottom: 16.38095rem; }
  .large-margin-bottom-44 {
    margin-bottom: 16.7619rem; }
  .large-margin-bottom-45 {
    margin-bottom: 17.14286rem; }
  .large-margin-bottom-46 {
    margin-bottom: 17.52381rem; }
  .large-margin-bottom-47 {
    margin-bottom: 17.90476rem; }
  .large-margin-bottom-48 {
    margin-bottom: 18.28571rem; }
  .large-margin-right-0 {
    margin-right: 0; }
  .large-margin-right-1 {
    margin-right: 0.38095rem; }
  .large-margin-right-2 {
    margin-right: 0.7619rem; }
  .large-margin-right-3 {
    margin-right: 1.14286rem; }
  .large-margin-right-4 {
    margin-right: 1.52381rem; }
  .large-margin-right-5 {
    margin-right: 1.90476rem; }
  .large-margin-right-6 {
    margin-right: 2.28571rem; }
  .large-margin-right-7 {
    margin-right: 2.66667rem; }
  .large-margin-right-8 {
    margin-right: 3.04762rem; }
  .large-margin-right-9 {
    margin-right: 3.42857rem; }
  .large-margin-right-10 {
    margin-right: 3.80952rem; }
  .large-margin-right-11 {
    margin-right: 4.19048rem; }
  .large-margin-right-12 {
    margin-right: 4.57143rem; }
  .large-margin-right-13 {
    margin-right: 4.95238rem; }
  .large-margin-right-14 {
    margin-right: 5.33333rem; }
  .large-margin-right-15 {
    margin-right: 5.71429rem; }
  .large-margin-right-16 {
    margin-right: 6.09524rem; }
  .large-margin-right-17 {
    margin-right: 6.47619rem; }
  .large-margin-right-18 {
    margin-right: 6.85714rem; }
  .large-margin-right-19 {
    margin-right: 7.2381rem; }
  .large-margin-right-20 {
    margin-right: 7.61905rem; }
  .large-margin-right-21 {
    margin-right: 8rem; }
  .large-margin-right-22 {
    margin-right: 8.38095rem; }
  .large-margin-right-23 {
    margin-right: 8.7619rem; }
  .large-margin-right-24 {
    margin-right: 9.14286rem; }
  .large-margin-right-25 {
    margin-right: 9.52381rem; }
  .large-margin-right-26 {
    margin-right: 9.90476rem; }
  .large-margin-right-27 {
    margin-right: 10.28571rem; }
  .large-margin-right-28 {
    margin-right: 10.66667rem; }
  .large-margin-right-29 {
    margin-right: 11.04762rem; }
  .large-margin-right-30 {
    margin-right: 11.42857rem; }
  .large-margin-right-31 {
    margin-right: 11.80952rem; }
  .large-margin-right-32 {
    margin-right: 12.19048rem; }
  .large-margin-right-33 {
    margin-right: 12.57143rem; }
  .large-margin-right-34 {
    margin-right: 12.95238rem; }
  .large-margin-right-35 {
    margin-right: 13.33333rem; }
  .large-margin-right-36 {
    margin-right: 13.71429rem; }
  .large-margin-right-37 {
    margin-right: 14.09524rem; }
  .large-margin-right-38 {
    margin-right: 14.47619rem; }
  .large-margin-right-39 {
    margin-right: 14.85714rem; }
  .large-margin-right-40 {
    margin-right: 15.2381rem; }
  .large-margin-right-41 {
    margin-right: 15.61905rem; }
  .large-margin-right-42 {
    margin-right: 16rem; }
  .large-margin-right-43 {
    margin-right: 16.38095rem; }
  .large-margin-right-44 {
    margin-right: 16.7619rem; }
  .large-margin-right-45 {
    margin-right: 17.14286rem; }
  .large-margin-right-46 {
    margin-right: 17.52381rem; }
  .large-margin-right-47 {
    margin-right: 17.90476rem; }
  .large-margin-right-48 {
    margin-right: 18.28571rem; }
  .large-margin-left-0 {
    margin-left: 0; }
  .large-margin-left-1 {
    margin-left: 0.38095rem; }
  .large-margin-left-2 {
    margin-left: 0.7619rem; }
  .large-margin-left-3 {
    margin-left: 1.14286rem; }
  .large-margin-left-4 {
    margin-left: 1.52381rem; }
  .large-margin-left-5 {
    margin-left: 1.90476rem; }
  .large-margin-left-6 {
    margin-left: 2.28571rem; }
  .large-margin-left-7 {
    margin-left: 2.66667rem; }
  .large-margin-left-8 {
    margin-left: 3.04762rem; }
  .large-margin-left-9 {
    margin-left: 3.42857rem; }
  .large-margin-left-10 {
    margin-left: 3.80952rem; }
  .large-margin-left-11 {
    margin-left: 4.19048rem; }
  .large-margin-left-12 {
    margin-left: 4.57143rem; }
  .large-margin-left-13 {
    margin-left: 4.95238rem; }
  .large-margin-left-14 {
    margin-left: 5.33333rem; }
  .large-margin-left-15 {
    margin-left: 5.71429rem; }
  .large-margin-left-16 {
    margin-left: 6.09524rem; }
  .large-margin-left-17 {
    margin-left: 6.47619rem; }
  .large-margin-left-18 {
    margin-left: 6.85714rem; }
  .large-margin-left-19 {
    margin-left: 7.2381rem; }
  .large-margin-left-20 {
    margin-left: 7.61905rem; }
  .large-margin-left-21 {
    margin-left: 8rem; }
  .large-margin-left-22 {
    margin-left: 8.38095rem; }
  .large-margin-left-23 {
    margin-left: 8.7619rem; }
  .large-margin-left-24 {
    margin-left: 9.14286rem; }
  .large-margin-left-25 {
    margin-left: 9.52381rem; }
  .large-margin-left-26 {
    margin-left: 9.90476rem; }
  .large-margin-left-27 {
    margin-left: 10.28571rem; }
  .large-margin-left-28 {
    margin-left: 10.66667rem; }
  .large-margin-left-29 {
    margin-left: 11.04762rem; }
  .large-margin-left-30 {
    margin-left: 11.42857rem; }
  .large-margin-left-31 {
    margin-left: 11.80952rem; }
  .large-margin-left-32 {
    margin-left: 12.19048rem; }
  .large-margin-left-33 {
    margin-left: 12.57143rem; }
  .large-margin-left-34 {
    margin-left: 12.95238rem; }
  .large-margin-left-35 {
    margin-left: 13.33333rem; }
  .large-margin-left-36 {
    margin-left: 13.71429rem; }
  .large-margin-left-37 {
    margin-left: 14.09524rem; }
  .large-margin-left-38 {
    margin-left: 14.47619rem; }
  .large-margin-left-39 {
    margin-left: 14.85714rem; }
  .large-margin-left-40 {
    margin-left: 15.2381rem; }
  .large-margin-left-41 {
    margin-left: 15.61905rem; }
  .large-margin-left-42 {
    margin-left: 16rem; }
  .large-margin-left-43 {
    margin-left: 16.38095rem; }
  .large-margin-left-44 {
    margin-left: 16.7619rem; }
  .large-margin-left-45 {
    margin-left: 17.14286rem; }
  .large-margin-left-46 {
    margin-left: 17.52381rem; }
  .large-margin-left-47 {
    margin-left: 17.90476rem; }
  .large-margin-left-48 {
    margin-left: 18.28571rem; }
  .large-margin-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .large-margin-1 {
    margin-top: 0.38095rem;
    margin-bottom: 0.38095rem; }
  .large-margin-2 {
    margin-top: 0.7619rem;
    margin-bottom: 0.7619rem; }
  .large-margin-3 {
    margin-top: 1.14286rem;
    margin-bottom: 1.14286rem; }
  .large-margin-4 {
    margin-top: 1.52381rem;
    margin-bottom: 1.52381rem; }
  .large-margin-5 {
    margin-top: 1.90476rem;
    margin-bottom: 1.90476rem; }
  .large-margin-6 {
    margin-top: 2.28571rem;
    margin-bottom: 2.28571rem; }
  .large-margin-7 {
    margin-top: 2.66667rem;
    margin-bottom: 2.66667rem; }
  .large-margin-8 {
    margin-top: 3.04762rem;
    margin-bottom: 3.04762rem; }
  .large-margin-9 {
    margin-top: 3.42857rem;
    margin-bottom: 3.42857rem; }
  .large-margin-10 {
    margin-top: 3.80952rem;
    margin-bottom: 3.80952rem; }
  .large-margin-11 {
    margin-top: 4.19048rem;
    margin-bottom: 4.19048rem; }
  .large-margin-12 {
    margin-top: 4.57143rem;
    margin-bottom: 4.57143rem; }
  .large-margin-13 {
    margin-top: 4.95238rem;
    margin-bottom: 4.95238rem; }
  .large-margin-14 {
    margin-top: 5.33333rem;
    margin-bottom: 5.33333rem; }
  .large-margin-15 {
    margin-top: 5.71429rem;
    margin-bottom: 5.71429rem; }
  .large-margin-16 {
    margin-top: 6.09524rem;
    margin-bottom: 6.09524rem; }
  .large-margin-17 {
    margin-top: 6.47619rem;
    margin-bottom: 6.47619rem; }
  .large-margin-18 {
    margin-top: 6.85714rem;
    margin-bottom: 6.85714rem; }
  .large-margin-19 {
    margin-top: 7.2381rem;
    margin-bottom: 7.2381rem; }
  .large-margin-20 {
    margin-top: 7.61905rem;
    margin-bottom: 7.61905rem; }
  .large-margin-21 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .large-margin-22 {
    margin-top: 8.38095rem;
    margin-bottom: 8.38095rem; }
  .large-margin-23 {
    margin-top: 8.7619rem;
    margin-bottom: 8.7619rem; }
  .large-margin-24 {
    margin-top: 9.14286rem;
    margin-bottom: 9.14286rem; }
  .large-margin-25 {
    margin-top: 9.52381rem;
    margin-bottom: 9.52381rem; }
  .large-margin-26 {
    margin-top: 9.90476rem;
    margin-bottom: 9.90476rem; }
  .large-margin-27 {
    margin-top: 10.28571rem;
    margin-bottom: 10.28571rem; }
  .large-margin-28 {
    margin-top: 10.66667rem;
    margin-bottom: 10.66667rem; }
  .large-margin-29 {
    margin-top: 11.04762rem;
    margin-bottom: 11.04762rem; }
  .large-margin-30 {
    margin-top: 11.42857rem;
    margin-bottom: 11.42857rem; }
  .large-margin-31 {
    margin-top: 11.80952rem;
    margin-bottom: 11.80952rem; }
  .large-margin-32 {
    margin-top: 12.19048rem;
    margin-bottom: 12.19048rem; }
  .large-margin-33 {
    margin-top: 12.57143rem;
    margin-bottom: 12.57143rem; }
  .large-margin-34 {
    margin-top: 12.95238rem;
    margin-bottom: 12.95238rem; }
  .large-margin-35 {
    margin-top: 13.33333rem;
    margin-bottom: 13.33333rem; }
  .large-margin-36 {
    margin-top: 13.71429rem;
    margin-bottom: 13.71429rem; }
  .large-margin-37 {
    margin-top: 14.09524rem;
    margin-bottom: 14.09524rem; }
  .large-margin-38 {
    margin-top: 14.47619rem;
    margin-bottom: 14.47619rem; }
  .large-margin-39 {
    margin-top: 14.85714rem;
    margin-bottom: 14.85714rem; }
  .large-margin-40 {
    margin-top: 15.2381rem;
    margin-bottom: 15.2381rem; }
  .large-margin-41 {
    margin-top: 15.61905rem;
    margin-bottom: 15.61905rem; }
  .large-margin-42 {
    margin-top: 16rem;
    margin-bottom: 16rem; }
  .large-margin-43 {
    margin-top: 16.38095rem;
    margin-bottom: 16.38095rem; }
  .large-margin-44 {
    margin-top: 16.7619rem;
    margin-bottom: 16.7619rem; }
  .large-margin-45 {
    margin-top: 17.14286rem;
    margin-bottom: 17.14286rem; }
  .large-margin-46 {
    margin-top: 17.52381rem;
    margin-bottom: 17.52381rem; }
  .large-margin-47 {
    margin-top: 17.90476rem;
    margin-bottom: 17.90476rem; }
  .large-margin-48 {
    margin-top: 18.28571rem;
    margin-bottom: 18.28571rem; }
  .large-padding-top-0 {
    padding-top: 0; }
  .large-padding-top-1 {
    padding-top: 0.38095rem; }
  .large-padding-top-2 {
    padding-top: 0.7619rem; }
  .large-padding-top-3 {
    padding-top: 1.14286rem; }
  .large-padding-top-4 {
    padding-top: 1.52381rem; }
  .large-padding-top-5 {
    padding-top: 1.90476rem; }
  .large-padding-top-6 {
    padding-top: 2.28571rem; }
  .large-padding-top-7 {
    padding-top: 2.66667rem; }
  .large-padding-top-8 {
    padding-top: 3.04762rem; }
  .large-padding-top-9 {
    padding-top: 3.42857rem; }
  .large-padding-top-10 {
    padding-top: 3.80952rem; }
  .large-padding-top-11 {
    padding-top: 4.19048rem; }
  .large-padding-top-12 {
    padding-top: 4.57143rem; }
  .large-padding-top-13 {
    padding-top: 4.95238rem; }
  .large-padding-top-14 {
    padding-top: 5.33333rem; }
  .large-padding-top-15 {
    padding-top: 5.71429rem; }
  .large-padding-top-16 {
    padding-top: 6.09524rem; }
  .large-padding-top-17 {
    padding-top: 6.47619rem; }
  .large-padding-top-18 {
    padding-top: 6.85714rem; }
  .large-padding-top-19 {
    padding-top: 7.2381rem; }
  .large-padding-top-20 {
    padding-top: 7.61905rem; }
  .large-padding-top-21 {
    padding-top: 8rem; }
  .large-padding-top-22 {
    padding-top: 8.38095rem; }
  .large-padding-top-23 {
    padding-top: 8.7619rem; }
  .large-padding-top-24 {
    padding-top: 9.14286rem; }
  .large-padding-top-25 {
    padding-top: 9.52381rem; }
  .large-padding-top-26 {
    padding-top: 9.90476rem; }
  .large-padding-top-27 {
    padding-top: 10.28571rem; }
  .large-padding-top-28 {
    padding-top: 10.66667rem; }
  .large-padding-top-29 {
    padding-top: 11.04762rem; }
  .large-padding-top-30 {
    padding-top: 11.42857rem; }
  .large-padding-top-31 {
    padding-top: 11.80952rem; }
  .large-padding-top-32 {
    padding-top: 12.19048rem; }
  .large-padding-top-33 {
    padding-top: 12.57143rem; }
  .large-padding-top-34 {
    padding-top: 12.95238rem; }
  .large-padding-top-35 {
    padding-top: 13.33333rem; }
  .large-padding-top-36 {
    padding-top: 13.71429rem; }
  .large-padding-top-37 {
    padding-top: 14.09524rem; }
  .large-padding-top-38 {
    padding-top: 14.47619rem; }
  .large-padding-top-39 {
    padding-top: 14.85714rem; }
  .large-padding-top-40 {
    padding-top: 15.2381rem; }
  .large-padding-top-41 {
    padding-top: 15.61905rem; }
  .large-padding-top-42 {
    padding-top: 16rem; }
  .large-padding-top-43 {
    padding-top: 16.38095rem; }
  .large-padding-top-44 {
    padding-top: 16.7619rem; }
  .large-padding-top-45 {
    padding-top: 17.14286rem; }
  .large-padding-top-46 {
    padding-top: 17.52381rem; }
  .large-padding-top-47 {
    padding-top: 17.90476rem; }
  .large-padding-top-48 {
    padding-top: 18.28571rem; }
  .large-padding-bottom-0 {
    padding-bottom: 0; }
  .large-padding-bottom-1 {
    padding-bottom: 0.38095rem; }
  .large-padding-bottom-2 {
    padding-bottom: 0.7619rem; }
  .large-padding-bottom-3 {
    padding-bottom: 1.14286rem; }
  .large-padding-bottom-4 {
    padding-bottom: 1.52381rem; }
  .large-padding-bottom-5 {
    padding-bottom: 1.90476rem; }
  .large-padding-bottom-6 {
    padding-bottom: 2.28571rem; }
  .large-padding-bottom-7 {
    padding-bottom: 2.66667rem; }
  .large-padding-bottom-8 {
    padding-bottom: 3.04762rem; }
  .large-padding-bottom-9 {
    padding-bottom: 3.42857rem; }
  .large-padding-bottom-10 {
    padding-bottom: 3.80952rem; }
  .large-padding-bottom-11 {
    padding-bottom: 4.19048rem; }
  .large-padding-bottom-12 {
    padding-bottom: 4.57143rem; }
  .large-padding-bottom-13 {
    padding-bottom: 4.95238rem; }
  .large-padding-bottom-14 {
    padding-bottom: 5.33333rem; }
  .large-padding-bottom-15 {
    padding-bottom: 5.71429rem; }
  .large-padding-bottom-16 {
    padding-bottom: 6.09524rem; }
  .large-padding-bottom-17 {
    padding-bottom: 6.47619rem; }
  .large-padding-bottom-18 {
    padding-bottom: 6.85714rem; }
  .large-padding-bottom-19 {
    padding-bottom: 7.2381rem; }
  .large-padding-bottom-20 {
    padding-bottom: 7.61905rem; }
  .large-padding-bottom-21 {
    padding-bottom: 8rem; }
  .large-padding-bottom-22 {
    padding-bottom: 8.38095rem; }
  .large-padding-bottom-23 {
    padding-bottom: 8.7619rem; }
  .large-padding-bottom-24 {
    padding-bottom: 9.14286rem; }
  .large-padding-bottom-25 {
    padding-bottom: 9.52381rem; }
  .large-padding-bottom-26 {
    padding-bottom: 9.90476rem; }
  .large-padding-bottom-27 {
    padding-bottom: 10.28571rem; }
  .large-padding-bottom-28 {
    padding-bottom: 10.66667rem; }
  .large-padding-bottom-29 {
    padding-bottom: 11.04762rem; }
  .large-padding-bottom-30 {
    padding-bottom: 11.42857rem; }
  .large-padding-bottom-31 {
    padding-bottom: 11.80952rem; }
  .large-padding-bottom-32 {
    padding-bottom: 12.19048rem; }
  .large-padding-bottom-33 {
    padding-bottom: 12.57143rem; }
  .large-padding-bottom-34 {
    padding-bottom: 12.95238rem; }
  .large-padding-bottom-35 {
    padding-bottom: 13.33333rem; }
  .large-padding-bottom-36 {
    padding-bottom: 13.71429rem; }
  .large-padding-bottom-37 {
    padding-bottom: 14.09524rem; }
  .large-padding-bottom-38 {
    padding-bottom: 14.47619rem; }
  .large-padding-bottom-39 {
    padding-bottom: 14.85714rem; }
  .large-padding-bottom-40 {
    padding-bottom: 15.2381rem; }
  .large-padding-bottom-41 {
    padding-bottom: 15.61905rem; }
  .large-padding-bottom-42 {
    padding-bottom: 16rem; }
  .large-padding-bottom-43 {
    padding-bottom: 16.38095rem; }
  .large-padding-bottom-44 {
    padding-bottom: 16.7619rem; }
  .large-padding-bottom-45 {
    padding-bottom: 17.14286rem; }
  .large-padding-bottom-46 {
    padding-bottom: 17.52381rem; }
  .large-padding-bottom-47 {
    padding-bottom: 17.90476rem; }
  .large-padding-bottom-48 {
    padding-bottom: 18.28571rem; }
  .large-padding-right-0 {
    padding-right: 0; }
  .large-padding-right-1 {
    padding-right: 0.38095rem; }
  .large-padding-right-2 {
    padding-right: 0.7619rem; }
  .large-padding-right-3 {
    padding-right: 1.14286rem; }
  .large-padding-right-4 {
    padding-right: 1.52381rem; }
  .large-padding-right-5 {
    padding-right: 1.90476rem; }
  .large-padding-right-6 {
    padding-right: 2.28571rem; }
  .large-padding-right-7 {
    padding-right: 2.66667rem; }
  .large-padding-right-8 {
    padding-right: 3.04762rem; }
  .large-padding-right-9 {
    padding-right: 3.42857rem; }
  .large-padding-right-10 {
    padding-right: 3.80952rem; }
  .large-padding-right-11 {
    padding-right: 4.19048rem; }
  .large-padding-right-12 {
    padding-right: 4.57143rem; }
  .large-padding-right-13 {
    padding-right: 4.95238rem; }
  .large-padding-right-14 {
    padding-right: 5.33333rem; }
  .large-padding-right-15 {
    padding-right: 5.71429rem; }
  .large-padding-right-16 {
    padding-right: 6.09524rem; }
  .large-padding-right-17 {
    padding-right: 6.47619rem; }
  .large-padding-right-18 {
    padding-right: 6.85714rem; }
  .large-padding-right-19 {
    padding-right: 7.2381rem; }
  .large-padding-right-20 {
    padding-right: 7.61905rem; }
  .large-padding-right-21 {
    padding-right: 8rem; }
  .large-padding-right-22 {
    padding-right: 8.38095rem; }
  .large-padding-right-23 {
    padding-right: 8.7619rem; }
  .large-padding-right-24 {
    padding-right: 9.14286rem; }
  .large-padding-right-25 {
    padding-right: 9.52381rem; }
  .large-padding-right-26 {
    padding-right: 9.90476rem; }
  .large-padding-right-27 {
    padding-right: 10.28571rem; }
  .large-padding-right-28 {
    padding-right: 10.66667rem; }
  .large-padding-right-29 {
    padding-right: 11.04762rem; }
  .large-padding-right-30 {
    padding-right: 11.42857rem; }
  .large-padding-right-31 {
    padding-right: 11.80952rem; }
  .large-padding-right-32 {
    padding-right: 12.19048rem; }
  .large-padding-right-33 {
    padding-right: 12.57143rem; }
  .large-padding-right-34 {
    padding-right: 12.95238rem; }
  .large-padding-right-35 {
    padding-right: 13.33333rem; }
  .large-padding-right-36 {
    padding-right: 13.71429rem; }
  .large-padding-right-37 {
    padding-right: 14.09524rem; }
  .large-padding-right-38 {
    padding-right: 14.47619rem; }
  .large-padding-right-39 {
    padding-right: 14.85714rem; }
  .large-padding-right-40 {
    padding-right: 15.2381rem; }
  .large-padding-right-41 {
    padding-right: 15.61905rem; }
  .large-padding-right-42 {
    padding-right: 16rem; }
  .large-padding-right-43 {
    padding-right: 16.38095rem; }
  .large-padding-right-44 {
    padding-right: 16.7619rem; }
  .large-padding-right-45 {
    padding-right: 17.14286rem; }
  .large-padding-right-46 {
    padding-right: 17.52381rem; }
  .large-padding-right-47 {
    padding-right: 17.90476rem; }
  .large-padding-right-48 {
    padding-right: 18.28571rem; }
  .large-padding-left-0 {
    padding-left: 0; }
  .large-padding-left-1 {
    padding-left: 0.38095rem; }
  .large-padding-left-2 {
    padding-left: 0.7619rem; }
  .large-padding-left-3 {
    padding-left: 1.14286rem; }
  .large-padding-left-4 {
    padding-left: 1.52381rem; }
  .large-padding-left-5 {
    padding-left: 1.90476rem; }
  .large-padding-left-6 {
    padding-left: 2.28571rem; }
  .large-padding-left-7 {
    padding-left: 2.66667rem; }
  .large-padding-left-8 {
    padding-left: 3.04762rem; }
  .large-padding-left-9 {
    padding-left: 3.42857rem; }
  .large-padding-left-10 {
    padding-left: 3.80952rem; }
  .large-padding-left-11 {
    padding-left: 4.19048rem; }
  .large-padding-left-12 {
    padding-left: 4.57143rem; }
  .large-padding-left-13 {
    padding-left: 4.95238rem; }
  .large-padding-left-14 {
    padding-left: 5.33333rem; }
  .large-padding-left-15 {
    padding-left: 5.71429rem; }
  .large-padding-left-16 {
    padding-left: 6.09524rem; }
  .large-padding-left-17 {
    padding-left: 6.47619rem; }
  .large-padding-left-18 {
    padding-left: 6.85714rem; }
  .large-padding-left-19 {
    padding-left: 7.2381rem; }
  .large-padding-left-20 {
    padding-left: 7.61905rem; }
  .large-padding-left-21 {
    padding-left: 8rem; }
  .large-padding-left-22 {
    padding-left: 8.38095rem; }
  .large-padding-left-23 {
    padding-left: 8.7619rem; }
  .large-padding-left-24 {
    padding-left: 9.14286rem; }
  .large-padding-left-25 {
    padding-left: 9.52381rem; }
  .large-padding-left-26 {
    padding-left: 9.90476rem; }
  .large-padding-left-27 {
    padding-left: 10.28571rem; }
  .large-padding-left-28 {
    padding-left: 10.66667rem; }
  .large-padding-left-29 {
    padding-left: 11.04762rem; }
  .large-padding-left-30 {
    padding-left: 11.42857rem; }
  .large-padding-left-31 {
    padding-left: 11.80952rem; }
  .large-padding-left-32 {
    padding-left: 12.19048rem; }
  .large-padding-left-33 {
    padding-left: 12.57143rem; }
  .large-padding-left-34 {
    padding-left: 12.95238rem; }
  .large-padding-left-35 {
    padding-left: 13.33333rem; }
  .large-padding-left-36 {
    padding-left: 13.71429rem; }
  .large-padding-left-37 {
    padding-left: 14.09524rem; }
  .large-padding-left-38 {
    padding-left: 14.47619rem; }
  .large-padding-left-39 {
    padding-left: 14.85714rem; }
  .large-padding-left-40 {
    padding-left: 15.2381rem; }
  .large-padding-left-41 {
    padding-left: 15.61905rem; }
  .large-padding-left-42 {
    padding-left: 16rem; }
  .large-padding-left-43 {
    padding-left: 16.38095rem; }
  .large-padding-left-44 {
    padding-left: 16.7619rem; }
  .large-padding-left-45 {
    padding-left: 17.14286rem; }
  .large-padding-left-46 {
    padding-left: 17.52381rem; }
  .large-padding-left-47 {
    padding-left: 17.90476rem; }
  .large-padding-left-48 {
    padding-left: 18.28571rem; }
  .large-padding-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .large-padding-1 {
    padding-top: 0.38095rem;
    padding-bottom: 0.38095rem; }
  .large-padding-2 {
    padding-top: 0.7619rem;
    padding-bottom: 0.7619rem; }
  .large-padding-3 {
    padding-top: 1.14286rem;
    padding-bottom: 1.14286rem; }
  .large-padding-4 {
    padding-top: 1.52381rem;
    padding-bottom: 1.52381rem; }
  .large-padding-5 {
    padding-top: 1.90476rem;
    padding-bottom: 1.90476rem; }
  .large-padding-6 {
    padding-top: 2.28571rem;
    padding-bottom: 2.28571rem; }
  .large-padding-7 {
    padding-top: 2.66667rem;
    padding-bottom: 2.66667rem; }
  .large-padding-8 {
    padding-top: 3.04762rem;
    padding-bottom: 3.04762rem; }
  .large-padding-9 {
    padding-top: 3.42857rem;
    padding-bottom: 3.42857rem; }
  .large-padding-10 {
    padding-top: 3.80952rem;
    padding-bottom: 3.80952rem; }
  .large-padding-11 {
    padding-top: 4.19048rem;
    padding-bottom: 4.19048rem; }
  .large-padding-12 {
    padding-top: 4.57143rem;
    padding-bottom: 4.57143rem; }
  .large-padding-13 {
    padding-top: 4.95238rem;
    padding-bottom: 4.95238rem; }
  .large-padding-14 {
    padding-top: 5.33333rem;
    padding-bottom: 5.33333rem; }
  .large-padding-15 {
    padding-top: 5.71429rem;
    padding-bottom: 5.71429rem; }
  .large-padding-16 {
    padding-top: 6.09524rem;
    padding-bottom: 6.09524rem; }
  .large-padding-17 {
    padding-top: 6.47619rem;
    padding-bottom: 6.47619rem; }
  .large-padding-18 {
    padding-top: 6.85714rem;
    padding-bottom: 6.85714rem; }
  .large-padding-19 {
    padding-top: 7.2381rem;
    padding-bottom: 7.2381rem; }
  .large-padding-20 {
    padding-top: 7.61905rem;
    padding-bottom: 7.61905rem; }
  .large-padding-21 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .large-padding-22 {
    padding-top: 8.38095rem;
    padding-bottom: 8.38095rem; }
  .large-padding-23 {
    padding-top: 8.7619rem;
    padding-bottom: 8.7619rem; }
  .large-padding-24 {
    padding-top: 9.14286rem;
    padding-bottom: 9.14286rem; }
  .large-padding-25 {
    padding-top: 9.52381rem;
    padding-bottom: 9.52381rem; }
  .large-padding-26 {
    padding-top: 9.90476rem;
    padding-bottom: 9.90476rem; }
  .large-padding-27 {
    padding-top: 10.28571rem;
    padding-bottom: 10.28571rem; }
  .large-padding-28 {
    padding-top: 10.66667rem;
    padding-bottom: 10.66667rem; }
  .large-padding-29 {
    padding-top: 11.04762rem;
    padding-bottom: 11.04762rem; }
  .large-padding-30 {
    padding-top: 11.42857rem;
    padding-bottom: 11.42857rem; }
  .large-padding-31 {
    padding-top: 11.80952rem;
    padding-bottom: 11.80952rem; }
  .large-padding-32 {
    padding-top: 12.19048rem;
    padding-bottom: 12.19048rem; }
  .large-padding-33 {
    padding-top: 12.57143rem;
    padding-bottom: 12.57143rem; }
  .large-padding-34 {
    padding-top: 12.95238rem;
    padding-bottom: 12.95238rem; }
  .large-padding-35 {
    padding-top: 13.33333rem;
    padding-bottom: 13.33333rem; }
  .large-padding-36 {
    padding-top: 13.71429rem;
    padding-bottom: 13.71429rem; }
  .large-padding-37 {
    padding-top: 14.09524rem;
    padding-bottom: 14.09524rem; }
  .large-padding-38 {
    padding-top: 14.47619rem;
    padding-bottom: 14.47619rem; }
  .large-padding-39 {
    padding-top: 14.85714rem;
    padding-bottom: 14.85714rem; }
  .large-padding-40 {
    padding-top: 15.2381rem;
    padding-bottom: 15.2381rem; }
  .large-padding-41 {
    padding-top: 15.61905rem;
    padding-bottom: 15.61905rem; }
  .large-padding-42 {
    padding-top: 16rem;
    padding-bottom: 16rem; }
  .large-padding-43 {
    padding-top: 16.38095rem;
    padding-bottom: 16.38095rem; }
  .large-padding-44 {
    padding-top: 16.7619rem;
    padding-bottom: 16.7619rem; }
  .large-padding-45 {
    padding-top: 17.14286rem;
    padding-bottom: 17.14286rem; }
  .large-padding-46 {
    padding-top: 17.52381rem;
    padding-bottom: 17.52381rem; }
  .large-padding-47 {
    padding-top: 17.90476rem;
    padding-bottom: 17.90476rem; }
  .large-padding-48 {
    padding-top: 18.28571rem;
    padding-bottom: 18.28571rem; } }

.small-align-right {
  justify-content: flex-end; }

.small-align-center {
  justify-content: center; }

.small-align-left {
  justify-content: flex-start; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-align-right {
    justify-content: flex-end; }
  .medium-align-center {
    justify-content: center; }
  .medium-align-left {
    justify-content: flex-start; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-align-right {
    justify-content: flex-end; }
  .large-align-center {
    justify-content: center; }
  .large-align-left {
    justify-content: flex-start; } }

ul.menu.small-horizontal {
  flex-wrap: wrap;
  flex-direction: row; }

ul.menu.small-vertical {
  flex-wrap: nowrap;
  flex-direction: column; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  ul.menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  ul.menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  ul.menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  ul.menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column; } }

body {
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden; }
  body .white {
    background-color: #fefefe; }
  body main {
    background-color: #fefefe;
    min-height: 100vh;
    flex: 1; }
  body.gray {
    background-color: #f4f4f4; }
    body.gray main {
      background-color: #f4f4f4; }
  body.black {
    background-color: #000000; }
    body.black main {
      background-color: #000000; }
  body.white {
    background-color: #fefefe; }
    body.white main {
      background-color: #fefefe; }
  body .off-canvas-content {
    min-height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column; }
  body section,
  body header {
    position: relative;
    z-index: 2;
    background-color: #fefefe; }
    body section.gray,
    body header.gray {
      background-color: #f4f4f4; }
      body section.gray main,
      body header.gray main {
        background-color: #f4f4f4; }
    body section.black,
    body header.black {
      background-color: #000000; }
      body section.black main,
      body header.black main {
        background-color: #000000; }
    body section.white,
    body header.white {
      background-color: #fefefe; }
      body section.white main,
      body header.white main {
        background-color: #fefefe; }
  body img {
    width: 100%;
    height: auto; }
  body video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer; }
  body .video-container {
    cursor: pointer; }
  body .grid-container.no-padding {
    padding-left: 0;
    padding-right: 0; }
  body #topbar-container, body footer {
    z-index: 1;
    background-color: #fefefe; }

._red {
  background-color: rgba(255, 0, 0, 0.2) !important;
  border: dashed 1px rgba(255, 0, 0, 0.8) !important; }

._green {
  background-color: rgba(0, 128, 0, 0.2) !important;
  border: dashed 1px rgba(0, 128, 0, 0.8) !important; }

._blue {
  background-color: rgba(0, 0, 255, 0.2) !important;
  border: dashed 1px rgba(0, 0, 255, 0.8) !important; }

._yellow {
  background-color: rgba(255, 255, 0, 0.2) !important;
  border: dashed 1px rgba(255, 255, 0, 0.8) !important; }

@font-face {
  font-family: 'SourceSansPro';
  src: url("../font/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro';
  src: url("../font/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

span,
a {
  line-height: 1.5; }

address {
  font-style: normal !important;
  line-height: 1.5;
  margin-bottom: 1rem; }

cite {
  color: #012c9f;
  font-style: normal !important;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif; }

a,
.link {
  transition: color 300ms ease;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: bold;
  color: #012c9f; }
  a svg.link-arrow,
  .link svg.link-arrow {
    transform: translateX(0px) translateY(2px);
    transition-duration: 300ms;
    height: 0.85714rem;
    margin-right: 8px;
    height: 1rem;
    width: auto; }
    a svg.link-arrow path,
    a svg.link-arrow rect,
    a svg.link-arrow line,
    a svg.link-arrow polyline,
    .link svg.link-arrow path,
    .link svg.link-arrow rect,
    .link svg.link-arrow line,
    .link svg.link-arrow polyline {
      stroke: #012c9f; }
  a:hover,
  .link:hover {
    transition: color 300ms ease;
    color: #012177; }
    a:hover svg.link-arrow,
    .link:hover svg.link-arrow {
      transform: translateX(2px) translateY(2px); }
      a:hover svg.link-arrow path,
      a:hover svg.link-arrow polyline,
      .link:hover svg.link-arrow path,
      .link:hover svg.link-arrow polyline {
        stroke: #012177; }
    a:hover svg.link-arrow.down,
    .link:hover svg.link-arrow.down {
      transform: translateX(0px) translateY(4px) rotate(0); }
      a:hover svg.link-arrow.down path,
      a:hover svg.link-arrow.down rect,
      a:hover svg.link-arrow.down line,
      a:hover svg.link-arrow.down polyline,
      .link:hover svg.link-arrow.down path,
      .link:hover svg.link-arrow.down rect,
      .link:hover svg.link-arrow.down line,
      .link:hover svg.link-arrow.down polyline {
        stroke: #012177; }
    a:hover svg.hover path,
    a:hover svg.hover rect,
    a:hover svg.hover line,
    a:hover svg.hover polyline,
    .link:hover svg.hover path,
    .link:hover svg.hover rect,
    .link:hover svg.hover line,
    .link:hover svg.hover polyline {
      fill: #012177; }

.menu a,
.menu .link {
  transition: color 300ms ease;
  color: #2e2e2e;
  text-transform: none;
  font-weight: normal; }

.fake-link:hover {
  cursor: pointer; }

.text-light {
  color: #7b7b7b; }

p a,
h1 a,
h2 a,
h3 a,
h4 a {
  text-transform: none; }

p p,
h1 p,
h2 p,
h3 p,
h4 p {
  line-height: inherit; }

.subline {
  font-size: 1.14286rem;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: #012c9f;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  text-transform: uppercase; }

.sans {
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

h1, .h1 {
  line-height: 1.44;
  letter-spacing: normal; }

h2, .h2 {
  line-height: 1.42;
  letter-spacing: normal; }

h4, .h4 {
  font-weight: bold; }

blockquote p {
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif; }

@media screen and (max-width: 53.0625em) {
  .grid-container.small-full {
    padding-left: 0;
    padding-right: 0; }
  a,
  .link {
    font-size: 0.85714rem;
    letter-spacing: 1px; }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1.33; } }

@media print, screen and (min-width: 53.125em) {
  h1, .h1 {
    line-height: 1.4;
    letter-spacing: normal; }
  h2, .h2 {
    line-height: 1.38;
    letter-spacing: normal; }
  blockquote p {
    font-size: 1rem; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-07 {
  0% {
    opacity: 0.7; }
  50% {
    opacity: 1; } }

@keyframes fade-out-07 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.7; } }

@keyframes gradient-scroll {
  0% {
    background-position: 100% 0%; }
  100% {
    background-position: 0% 100%; } }

@keyframes gradient-scroll-back {
  0% {
    background-position: 0% 100%; }
  100% {
    background-position: 100% 0%; } }

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes rotate-45-in {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-45deg); } }

@keyframes rotate-45-out {
  0% {
    transform: rotate(-45deg); }
  100% {
    transform: rotate(0); } }

@keyframes slide-right-in {
  0% {
    transform: translateX(0px); }
  100% {
    transform: translateX(2px); } }

@keyframes slide-right-out {
  0% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0px); } }

@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0%); }
  100% {
    transform: translateY(100%); } }

@keyframes zoom-fade-in {
  0% {
    opacity: 0;
    transform: scale(1); }
  100% {
    opacity: 1;
    transform: scale(1.2); } }

@keyframes zoom-fade-out {
  0% {
    opacity: 1;
    transform: scale(1.2); }
  100% {
    opacity: 0;
    transform: scale(1); } }

@keyframes zoom-in {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

@keyframes zoom-out {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes zoom-in-gradient {
  0% {
    transform: scale(1);
    background-position: 0% 0%; }
  10% {
    transform: scale(1.1); }
  50% {
    background-position: 100% 100%; }
  100% {
    transform: scale(1.1);
    background-position: 0% 0%; } }

@keyframes grow {
  0% {
    width: 3%;
    left: -2%;
    right: 98%; }
  50% {
    width: 102%;
    left: -1%;
    right: -1%; }
  98% {
    width: 4%;
    right: -2%;
    left: 98%; }
  100% {
    width: 2%;
    right: -1%;
    left: 99%; } }

@keyframes vehicle-rotate-in {
  0% {
    transform: rotate(6deg);
    transform-origin: 200% 100%;
    opacity: 0.9; }
  100% {
    transform: rotate(0);
    opacity: 1;
    transform-origin: 200% 100%; } }

@keyframes vehicle-rotate-out {
  0% {
    transform-origin: 200% 100%;
    transform: rotate(0);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: rotate(-6deg);
    transform-origin: 200% 100%; } }

@keyframes text-rotate-in {
  0% {
    transform: rotate(6deg);
    transform-origin: -200% 100%;
    opacity: 0.9; }
  100% {
    transform: rotate(0);
    transform-origin: -200% 100%;
    opacity: 1; } }

@keyframes text-rotate-out {
  0% {
    transform: rotate(0);
    transform-origin: -200% 100%;
    opacity: 1; }
  100% {
    transform: rotate(-6deg);
    transform-origin: -200% 100%;
    opacity: 0; } }

@keyframes slide-right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(10px); } }

@keyframes slide-right-out {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

[data-aos="video-icon"] {
  transition-property: transform;
  transform: translateY(0%); }
  [data-aos="video-icon"].aos-animate {
    transform: translateY(50%); }

.button,
.menu a.button {
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
  padding: 0.5rem 2rem;
  border-radius: 1.6rem;
  line-height: 0.8rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  font-size: 0.8rem;
  text-transform: uppercase; }
  .button:hover,
  .menu a.button:hover {
    color: #fefefe; }

.topbar-container {
  position: relative;
  z-index: 100;
  height: 76px; }

.main-nav {
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 100%;
  background-color: #fefefe; }
  .main-nav a {
    padding-top: 0px;
    padding-bottom: 0px;
    color: black;
    text-transform: none;
    font-size: 20px;
    font-weight: normal;
    height: 100%; }
    .main-nav a:hover {
      transition: color 300ms ease;
      color: #012177; }
    .main-nav a.is-active {
      font-weight: 600; }
    .main-nav a svg {
      max-height: 100%;
      height: 34px;
      width: auto; }
  .main-nav.is-stuck {
    position: fixed;
    z-index: 5;
    width: 100%; }
    .main-nav.is-stuck.is-at-top {
      top: 0; }
    .main-nav.is-stuck.is-at-bottom {
      bottom: 0; }
  .main-nav.is-anchored {
    position: relative;
    right: auto;
    left: auto; }
    .main-nav.is-anchored.is-at-bottom {
      bottom: 0; }
  .main-nav .grid-container {
    padding: 1rem; }
    .main-nav .grid-container .top-bar {
      padding: 0;
      flex-wrap: nowrap; }
      .main-nav .grid-container .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .main-nav .grid-container .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; }
      .main-nav .grid-container .top-bar .top-bar-left .menu,
      .main-nav .grid-container .top-bar .top-bar-right .menu {
        height: 100%; }
        .main-nav .grid-container .top-bar .top-bar-left .menu li a.nav-link,
        .main-nav .grid-container .top-bar .top-bar-right .menu li a.nav-link {
          line-height: 3.80952rem;
          padding-top: 0;
          padding-bottom: 0;
          transition: color 500ms ease; }
      .main-nav .grid-container .top-bar .top-bar-title {
        text-align: center;
        margin: 0; }
        .main-nav .grid-container .top-bar .top-bar-title a {
          display: block;
          width: 100%;
          height: 3.80952rem;
          background-color: #f4f4f4;
          padding-top: 0.47619rem;
          padding-bottom: 0.47619rem; }
          .main-nav .grid-container .top-bar .top-bar-title a svg {
            height: 100%;
            width: auto;
            opacity: 1; }
  @media screen and (max-width: 53.0625em) {
    .main-nav .grid-container .top-bar .top-bar-title {
      text-align: left; }
      .main-nav .grid-container .top-bar .top-bar-title a {
        background-color: transparent;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 3.04762rem; } }

.off-canvas {
  background-color: #fefefe; }
  .off-canvas .grid-container {
    min-height: 100vh; }
    .off-canvas .grid-container a {
      color: inherit;
      text-transform: none;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-weight: normal; }
      .off-canvas .grid-container a:hover {
        color: #012177; }
      .off-canvas .grid-container a:focus {
        outline: none; }
    .off-canvas .grid-container .top-bar {
      flex-wrap: nowrap; }
      .off-canvas .grid-container .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .off-canvas .grid-container .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; }
      .off-canvas .grid-container .top-bar .top-bar-left,
      .off-canvas .grid-container .top-bar .top-bar-right {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0; }
        .off-canvas .grid-container .top-bar .top-bar-left .logo,
        .off-canvas .grid-container .top-bar .top-bar-right .logo {
          width: auto;
          height: 3.09524rem; }
    .off-canvas .grid-container .menu-text li a {
      padding-left: 0 !important; }
    .off-canvas .grid-container .footer-accordion a.accordion-title h2 {
      margin-top: 0; }

section.paragraph .content {
  position: relative; }
  section.paragraph .content .box {
    background: #fefefe; }
  section.paragraph .content .menu li a {
    padding-left: 0; }
  section.paragraph .content .link-list {
    margin-left: 0; }
    section.paragraph .content .link-list a {
      text-transform: none; }
    section.paragraph .content .link-list li {
      margin-bottom: 1rem; }
  section.paragraph .content .text-container {
    position: relative; }
    section.paragraph .content .text-container h3.label {
      height: 6rem;
      width: 6rem;
      line-height: 6rem;
      border-radius: 50%;
      text-align: center;
      background-color: #000000;
      color: #fefefe;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%) rotate(-10deg);
      z-index: 5; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .content .text-container.padding-left-middle, section.paragraph .content .text-container.padding-right-middle {
        padding-top: 2rem; } }
    section.paragraph .content .text-container .text {
      z-index: 10;
      position: relative; }
    @media print, screen and (min-width: 53.125em) {
      section.paragraph .content .text-container blockquote {
        margin-left: 8%;
        margin-right: -8%; } }
    section.paragraph .content .text-container blockquote {
      margin-top: 2rem;
      margin-bottom: 2rem; }

section.paragraph .illustration-container {
  position: relative; }
  section.paragraph .illustration-container .illustration {
    z-index: 2;
    position: relative;
    transform: translateX(-50%); }
    section.paragraph .illustration-container .illustration svg {
      height: auto;
      width: 100%; }
  section.paragraph .illustration-container.right {
    left: 100%; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .illustration-container.right .illustration {
        transform: translateX(-25%); } }
  section.paragraph .illustration-container.left {
    right: 0; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .illustration-container.left .illustration {
        transform: translateX(-75%); } }

@media screen and (max-width: 53.0625em) {
  section.img-text.left, section.img-text.right, section.img-text.middle {
    padding-top: 3.5rem; } }

@media print, screen and (min-width: 53.125em) {
  section.img-text.left, section.img-text.right {
    padding-top: 6rem; }
  section.img-text.middle {
    padding-bottom: 6rem; } }

section.img-text .grid-container .box {
  position: relative; }
  section.img-text .grid-container .box .box-image, section.img-text .grid-container .box box-image-mobile {
    position: relative; }
  section.img-text .grid-container .box .illustration {
    position: absolute; }
    @media screen and (max-width: 53.0625em) {
      section.img-text .grid-container .box .illustration svg {
        height: 7rem;
        width: auto; } }
    @media print, screen and (min-width: 53.125em) {
      section.img-text .grid-container .box .illustration svg {
        height: 10rem;
        width: auto; } }
    section.img-text .grid-container .box .illustration.right {
      top: 0px;
      right: 0px;
      transform: translate(50%, -50%); }
    section.img-text .grid-container .box .illustration.left {
      top: 0px;
      transform: translate(-50%, -50%); }
    section.img-text .grid-container .box .illustration.middle-left {
      bottom: 0px;
      right: 0px;
      transform: translate(50%, 50%); }
    section.img-text .grid-container .box .illustration.middle-right {
      bottom: 0px;
      left: 0px;
      transform: translate(-50%, 50%); }

section.img-text .grid-container .grid-x .box-image {
  min-height: 400px;
  background-size: cover;
  background-position: center; }

section.img-text .grid-container .grid-x .box-image-mobile {
  height: 200px;
  background-size: cover;
  background-position: center; }

section.img-text .grid-container .grid-x .link svg .cls-1, section.img-text .grid-container .grid-x .link svg .cls-2 {
  fill: none !important; }

section.img-text .grid-container .grid-x .link svg polyline {
  stroke: #e74436; }

section.img-text .grid-container .grid-x .link svg polyline:hover {
  stroke: #bf2417; }

section.icon-text-list svg {
  width: 100%;
  height: auto; }

section.icon-text-list .entry-text ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center; }
  [data-whatinput='mouse'] section.icon-text-list .entry-text ul li {
    outline: 0; }
  section.icon-text-list .entry-text ul a,
  section.icon-text-list .entry-text ul .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0.5rem; }
  section.icon-text-list .entry-text ul input,
  section.icon-text-list .entry-text ul select,
  section.icon-text-list .entry-text ul a,
  section.icon-text-list .entry-text ul button {
    margin-bottom: 0; }
  section.icon-text-list .entry-text ul input {
    display: inline-block; }
  section.icon-text-list .entry-text ul li {
    margin-bottom: 0.8rem;
    position: relative; }
    section.icon-text-list .entry-text ul li:not(:last-child):after {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.4rem;
      width: 1rem;
      left: calc(50% - 0.5rem);
      border-top: 2px solid #aeaeae; }

section.icon-text-list .entry-text.blue ul li:not(:last-child):after {
  border-color: #012c9f; }

section.icon-text-list .entry-text.green ul li:not(:last-child):after {
  border-color: #008a00; }

section.icon-text-list .entry-text.red ul li:not(:last-child):after {
  border-color: #e74436; }

section.icon-text-list .entry-text.yellow ul li:not(:last-child):after {
  border-color: #ffc800; }

section.img img {
  object-fit: cover;
  max-height: 600px;
  width: 100%; }

@media print, screen and (min-width: 96.875em) {
  section.img img {
    max-height: 800px; } }

section.path {
  background-color: #f4f4f4; }
  section.path .full-height {
    height: 100% !important; }
  section.path .number {
    background-color: #f4f4f4;
    height: 40px; }
  section.path .last-item {
    background-color: #f4f4f4;
    transform: translate(0%, 0%); }
  section.path .icon {
    height: 130px; }
    section.path .icon svg {
      width: 100%;
      height: auto; }
  section.path .text {
    height: 130px; }
  section.path .path-wrapper {
    position: relative; }
  section.path .path-row {
    height: 300px; }
  section.path .path {
    height: 306px;
    position: absolute;
    top: 0;
    z-index: -1; }
    section.path .path.straight-left {
      border-top: 6px solid #5c8bd5;
      transform: translate(1%, -50%); }
    section.path .path.straight-right {
      border-top: 6px solid #5c8bd5;
      transform: translate(100%, -50%); }
    section.path .path.right-curve {
      border-top: 6px solid #5c8bd5;
      border-bottom: 6px solid #5c8bd5;
      border-right: 6px solid #5c8bd5;
      border-radius: 0px 100px 100px 0px;
      transform: translate(100%, -50%); }
    section.path .path.left-curve {
      border-top: 6px solid #5c8bd5;
      border-bottom: 6px solid #5c8bd5;
      border-left: 6px solid #5c8bd5;
      border-radius: 100px 0px 0px 100px;
      transform: translate(1%, -50%); }
  section.path .small-view .svg svg {
    width: 100%;
    height: auto; }

section.table {
  background-image: linear-gradient(66deg, #b3deff 44%, #e4f3ff 149%); }
  section.table .item {
    border-top: 1px solid #8a8a8a; }
    section.table .item .title {
      text-transform: uppercase;
      color: #012c9f; }

section.projects a.slick-trigger.expanded svg {
  transform: rotate(-180deg); }

section.projects .more-info {
  display: none; }
  section.projects .more-info.expanded {
    display: flex; }

section.projects .close-toggler {
  display: none; }
  section.projects .close-toggler svg,
  section.projects .close-toggler .text {
    display: inline-block;
    vertical-align: middle; }
  section.projects .close-toggler.expanded {
    display: flex; }

section.projects .img {
  min-height: 400px;
  max-height: 640px;
  background-size: cover;
  background-position: center;
  background-color: #f4f4f4; }

section.projects .cat b {
  color: #008a00; }

section.projects a.icon-button.bg-blue:hover {
  animation: gradient-scroll 300ms ease both !important; }

section.projects a.icon-button.bg-green:hover {
  animation: gradient-scroll 300ms ease both !important; }

section.projects a.icon-button.bg-red:hover {
  animation: gradient-scroll 300ms ease both !important; }

section.projects a.icon-button.bg-yellow:hover {
  animation: gradient-scroll 300ms ease both !important; }

section.projects a.icon-button.bg-white {
  transition: background-color 300ms ease-out; }
  section.projects a.icon-button.bg-white:hover {
    background-color: #f4f4f4;
    transition: background-color 300ms ease-in; }

section.projects .icon-button {
  border-radius: 30px;
  outline: 0 !important; }
  section.projects .icon-button .icon {
    border-right: solid 1px #a4d56b; }
  section.projects .icon-button .subtitle {
    text-transform: uppercase; }
  section.projects .icon-button .icon.blue {
    border-right: solid 1px #012c9f; }
  section.projects .icon-button.bg-blue {
    background-color: #fefefe;
    background-image: linear-gradient(17deg, rgba(1, 44, 159, 0.9) 0%, rgba(1, 44, 159, 0.7) 100%);
    color: #fefefe;
    background-color: transparent;
    transform: translateZ(0);
    background-size: 200% 200%;
    background-position: 100% 0%;
    will-change: background-position;
    animation: gradient-scroll-back 700ms ease both !important; }
    section.projects .icon-button.bg-blue .icon {
      border-right: solid 1px white; }
  section.projects .icon-button .icon.green {
    border-right: solid 1px #008a00; }
  section.projects .icon-button.bg-green {
    background-color: #fefefe;
    background-image: linear-gradient(17deg, rgba(0, 138, 0, 0.9) 0%, rgba(0, 138, 0, 0.7) 100%);
    color: #fefefe;
    background-color: transparent;
    transform: translateZ(0);
    background-size: 200% 200%;
    background-position: 100% 0%;
    will-change: background-position;
    animation: gradient-scroll-back 700ms ease both !important; }
    section.projects .icon-button.bg-green .icon {
      border-right: solid 1px white; }
  section.projects .icon-button .icon.red {
    border-right: solid 1px #e74436; }
  section.projects .icon-button.bg-red {
    background-color: #fefefe;
    background-image: linear-gradient(17deg, rgba(231, 68, 54, 0.9) 0%, rgba(231, 68, 54, 0.7) 100%);
    color: #fefefe;
    background-color: transparent;
    transform: translateZ(0);
    background-size: 200% 200%;
    background-position: 100% 0%;
    will-change: background-position;
    animation: gradient-scroll-back 700ms ease both !important; }
    section.projects .icon-button.bg-red .icon {
      border-right: solid 1px white; }
  section.projects .icon-button .icon.yellow {
    border-right: solid 1px #ffc800; }
  section.projects .icon-button.bg-yellow {
    background-color: #fefefe;
    background-image: linear-gradient(17deg, rgba(255, 200, 0, 0.9) 0%, rgba(255, 200, 0, 0.7) 100%);
    color: #fefefe;
    background-color: transparent;
    transform: translateZ(0);
    background-size: 200% 200%;
    background-position: 100% 0%;
    will-change: background-position;
    animation: gradient-scroll-back 700ms ease both !important; }
    section.projects .icon-button.bg-yellow .icon {
      border-right: solid 1px white; }
  section.projects .icon-button.bg-white {
    color: #008a00;
    background-color: #fefefe; }
  section.projects .icon-button svg {
    max-width: 100%;
    width: 80px;
    height: 80px; }

section.projects .white-bg {
  background-color: #fefefe; }

section.projects .slider-container .slider .slick-slide {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 0.5;
  outline: 0 !important; }
  section.projects .slider-container .slider .slick-slide a {
    outline: 0 !important; }

section.projects .slider-container .slider .slick-current {
  opacity: 1.0; }

section.projects .slider-container .slider .slick-dots {
  margin-top: 1rem; }

section.projects .slider .slick-dots {
  position: relative;
  bottom: 0;
  line-height: 0; }
  section.projects .slider .slick-dots li button:before {
    font-size: 40px;
    opacity: 0.25 !important; }
  section.projects .slider .slick-dots li.slick-active button:before {
    opacity: 1.0 !important; }

section.projects .slider.blue .slick-dots li button:before {
  color: #012c9f !important; }

section.projects .slider.green .slick-dots li button:before {
  color: #008a00 !important; }

section.projects .slider.red .slick-dots li button:before {
  color: #e74436 !important; }

section.projects .slider.yellow .slick-dots li button:before {
  color: #ffc800 !important; }

section.projects .project-archive .cat {
  margin-bottom: 0; }

section.projects .project-archive .entry {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #8a8a8a; }

section.projects .icon-button svg.icon-white g.shade path,
section.projects .icon-button svg.icon-white g.shade polyline,
section.projects .icon-button svg.icon-white g.shade polygon,
section.projects .icon-button svg.icon-white g.shade line,
section.projects .icon-button svg.icon-white g.shade rect,
section.projects .icon-button svg.icon-white g.shade circle {
  fill: rgba(254, 254, 254, 0.2) !important; }

section.projects .icon-button svg.icon-white g.outline path,
section.projects .icon-button svg.icon-white g.outline polyline,
section.projects .icon-button svg.icon-white g.outline line,
section.projects .icon-button svg.icon-white g.outline rect,
section.projects .icon-button svg.icon-white g.outline circle,
section.projects .icon-button svg.icon-white g.outline polygon {
  stroke: #fefefe !important;
  fill: none; }

section.team .photo img {
  border-radius: 50%; }

section.team .description p {
  margin-bottom: 0; }

header.header {
  z-index: 1; }
  header.header .header-conatiner {
    position: relative; }
    header.header .header-conatiner .img-video-container {
      height: calc(100vh - 80px);
      max-height: 800px; }
      header.header .header-conatiner .img-video-container .video-container,
      header.header .header-conatiner .img-video-container .header-image {
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
        z-index: 1;
        position: fixed;
        will-change: transform; }
        header.header .header-conatiner .img-video-container .video-container .image,
        header.header .header-conatiner .img-video-container .header-image .image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center; }
    header.header .header-conatiner .logo-container {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0; }
      header.header .header-conatiner .logo-container .logo svg {
        width: 100%;
        height: auto; }
      header.header .header-conatiner .logo-container.center {
        bottom: 50%;
        left: 50%; }
        header.header .header-conatiner .logo-container.center .logo {
          transform: translate(-50%, 50%); }
  @media print, screen and (min-width: 96.875em) {
    header.header .header-conatiner .img-video-container {
      max-height: 80vh; } }

.footer {
  background-color: #f4f4f4; }
  .footer .footer-subline {
    background-color: #f4f4f4; }
  .footer .footer-subline {
    border-top: 1px solid #A1A1A1; }
    .footer .footer-subline .menu li {
      margin-left: 0;
      margin-right: 1rem; }

@media screen and (max-width: 53.0625em) {
  section.lead .grid-container {
    padding-left: 1rem;
    padding-bottom: 2rem; } }
